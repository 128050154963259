/**
 *
 * LaneBoard
 *
 */

import Wrapper from './Wrapper';
import React from 'react';
import { BOARD_WIDTH } from '../Lane/constants';

//import styles from './styles.css';

interface Props {
  board: number;
  onClick?: Function;
  isSelected: boolean;
  height: number;
  width: number;
}

export const LaneBoard = (props: Props) => {
  let opa = 0.45;
  if (props.board % 5 === 0) {
    opa = 0.5;
  }

  const width = BOARD_WIDTH;
  return (
    <Wrapper
      key={`board-${props.board}`}
      $board={props.board}
      $boardSelected={props.isSelected}
      width={width}
      height={props.height}
      x={(39 - props.board) * width}
      y={0}
      $opacity={opa}
      onClick={evt => {
        if (props.onClick) {
          const e = evt.target;
          const dim = (e as Element).getBoundingClientRect();
          const lengthOfBoardInPx = dim.bottom - dim.top;
          const pixelsPerFt = lengthOfBoardInPx / 62.833;
          const y = evt.clientY - dim.top;
          const lengthAtClick = 62.833 - y / pixelsPerFt;
          props.onClick(props.board, lengthAtClick);
        }
      }}
    />
  );
};

export default LaneBoard;
