import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { BallDeckExitPointState } from './types';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectBallDeckExitPointReport } from './selectors';

export const initialState: BallDeckExitPointState = {};

const mapPointToBoard = {
  '1': 36,
  '2': 32,
  '3': 25,
  '4': 20,
  '6': 15,
  '7': 9,
};
const slice = createSlice({
  name: 'ballDeckExitPoint',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        gameManagerActions.commit,
        (state, action: PayloadAction<any>) => {
          console.log(JSON.stringify(state, null, 2));
          return initialState;
          // return state;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.ballExitPoint;
        },
      )
      .addCase(
        gameManagerActions.setEditDelivery,
        (
          state,
          action: PayloadAction<{
            ballExitPoint?: ReturnType<typeof selectBallDeckExitPointReport>;
          }>,
        ) => {
          state.ballExitPoint = action.payload.ballExitPoint;
        },
      )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.ballExitPoint = state.save;
        },
      );
  },
  reducers: {
    setBallDeckExitPoint(state, action: PayloadAction<any>) {
      state.ballExitPoint = action.payload;
      state.deckExitBoard = mapPointToBoard[action.payload];
    },
    resetBallDeckExitPoint(state, action: PayloadAction<any>) {
      return { ...initialState };
    },
    setBallDeckExitBoard(state, action: PayloadAction<string>) {
      // untested
      for (const key in mapPointToBoard) {
        if (action.payload >= mapPointToBoard[key]) {
          state.ballExitPoint = key;
          state.deckExitBoard = mapPointToBoard[key];
          return state;
        }
      }
    },
  },
});

export const { actions: ballDeckExitPointActions } = slice;

export const useBallDeckExitPointSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useBallDeckExitPointSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
