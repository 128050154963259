export async function putGameInfo(data) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/game`,
    // 'https://0f0t3f9ue4.execute-api.us-west-1.amazonaws.com/dev/hello',
    // 'http://localhost:3001/dev/hello',
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
        'Content-Type': 'application/json',
      },
      method: 'put',
      // credentials: 'include',
      body: JSON.stringify(data),
    },
  );
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}
