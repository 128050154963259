import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { FrameNotesState } from './types';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectFrameNotesReport } from './selectors';

export const initialState: FrameNotesState = {};

const slice = createSlice({
  name: 'frameNotes',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        gameManagerActions.newFrame,
        (state, action: PayloadAction<any>) => {
          return initialState;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.note;
        },
      )
      .addCase(
        gameManagerActions.setEditDelivery,
        (
          state,
          action: PayloadAction<{
            frameNotes?: ReturnType<typeof selectFrameNotesReport>;
          }>,
        ) => {
          state.note = action.payload.frameNotes;
        },
      )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.note = state.save;
        },
      );
  },
  reducers: {
    addNote(state, action: PayloadAction<{ note: string }>) {
      state.note = action.payload.note;
    },
  },
});

export const { actions: frameNotesActions } = slice;

export const useFrameNotesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFrameNotesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
