import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import {
  getCount,
  getInactiveCount,
} from 'app/components/GameManager/slice/util';

const selectSlice = (state: RootState) =>
  state.pinFallController || initialState;

export const selectPinFallController = createSelector([selectSlice], state => {
  return {
    ...state,
    count: getCount(state.status) + getInactiveCount(state.status),
  };
});

export const selectPinFallReport = createSelector([selectSlice], state => ({
  pinStatus: state.status,
}));
