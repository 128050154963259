import { putSet } from 'api/set';
import {
  take,
  call,
  put,
  select,
  takeLatest,
  delay,
  fork,
} from 'redux-saga/effects';
import { bowlingSetActions as actions } from '.';
import {
  targetLeftLaneActions,
  targetRightLaneActions,
} from 'app/components/lane/Lane/slice';
import { selectBowlingSet } from './selectors';
import { gameManagerActions } from 'app/components/GameManager/slice';

// function* doSomething() {}

export function* bowlingSetSaga() {
  // yield call(listApi);
  yield takeLatest(actions.start.type, start);
  yield takeLatest(actions.complete.type, finish);
}

export function* finish() {
  yield fork(updateSetApi);
  yield put(gameManagerActions.completeSet());
}
export function* start({ payload }: ReturnType<typeof actions.start>) {
  console.log(`here with ${payload.id}`);
  // is it okay to tack on target/laydown when it has nothing to do with
  // bowling set action (other than to initialize the target?)
  yield put(
    targetLeftLaneActions.setTargetAndLaydown({
      laydown: payload.laydown,
      target: payload.target as number,
    }),
  );
  yield put(
    targetRightLaneActions.setTargetAndLaydown({
      laydown: payload.laydown,
      target: payload.target as number,
    }),
  );
  yield call(apiSynchronizer);
}

export function* apiSynchronizer() {
  yield put(actions.updateApiStart());
  yield call(updateResource);
}
export function* updateResource() {
  const apiResponse = yield call(updateSetApi);
  yield put(actions.updateApiSuccess(apiResponse));
}
export function* updateSetApi() {
  const set: ReturnType<typeof selectBowlingSet> = yield select(
    selectBowlingSet,
  );
  while (true) {
    try {
      const apiResponse = yield call(
        putSet,
        set.id as string,
        set.event as string,
        set.type as string,
        set.startTime as number,
        set.finishTime,
      );
      return apiResponse;
    } catch (error) {
      yield put(actions.updateApiRetry(error));
      yield delay(20000);
    }
  }
}
