/**
 *
 * LaneArrows
 *
 */

import React from 'react';
import RangeFinder from './LaneArrow';

interface Props {
  height: number;
  boardWidth: number;
}

export const RangeFinders = (props: Props) => {
  // eslint-disable-line react/prefer-stateless-function

  const generateArrows = () => {
    const arrows = [];
    const arrowHeight = 6;
    const rankSeparation = 24 / 3;

    return (
      <svg>
        <RangeFinder
          key="range-finder-10"
          x={props.boardWidth * 30 - props.boardWidth / 2}
          y={0}
          height={36}
          width={props.boardWidth}
        />
        <RangeFinder
          key="range-finder-30"
          x={props.boardWidth * 10 - props.boardWidth / 2}
          y={0}
          height={36}
          width={props.boardWidth}
        />
        <RangeFinder
          key="range-finder-15"
          x={props.boardWidth * 25 - props.boardWidth / 2}
          y={6 * 12}
          height={36}
          width={props.boardWidth}
        />
        <RangeFinder
          key="range-finder-25"
          x={props.boardWidth * 15 - props.boardWidth / 2}
          y={6 * 12}
          height={36}
          width={props.boardWidth}
        />
      </svg>
    );
  };
  const arrows = generateArrows();
  return <svg>{arrows}</svg>;
};

export default RangeFinders;
