import {
  take,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import { notificationsActions as actions } from '.';
import { toast } from 'react-toastify';

const toastIds: any[] = [];
function* toastIt(action: ReturnType<typeof actions.toast>) {
  const { toastId } = action.payload.options
    ? action.payload.options
    : { toastId: '' };
  if (toastId?.toString()) {
    if (toastIds.includes(toastId.toString())) {
      return;
    } else {
      toastIds.push(toastId.toString());
    }
  }
  toast(action.payload.component as string, {
    type: 'info',
    ...action.payload.options,
  });
  yield;
}

export function* notificationsSaga() {
  yield takeEvery(actions.toast.type, toastIt);
}
