/**
 *
 * BrowserExperienceNotice
 *
 */
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../Notifications/slice';

interface Props {
  pageId?: string;
}

export function BrowserExperienceNotice(props: Props) {
  const { pageId } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isMobile) {
      dispatch(
        notificationsActions.toast({
          component: 'This page is best viewed on a desktop device',
          options: {
            autoClose: false,
            type: 'warning',
            toastId: `browser-experience-notice-${pageId}`,
          },
        }),
      );
    }
  });

  return <></>;
}
