import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  frameNo: number;
}

export function FrameLabel(props: Props) {
  const { frameNo } = props;
  return (
    <Box>
      <Typography textAlign="center" fontSize=".7em">
        {frameNo}
      </Typography>
    </Box>
  );
}
