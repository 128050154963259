import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ssoSaga } from './saga';
import { SsoState } from './types';
import { isAutoGeneratedRow } from '@mui/x-data-grid/hooks/features/rows/gridRowsUtils';

export const initialState: SsoState = {
  isAuthed: false,
  loading: true,
};

const slice = createSlice({
  name: 'sso',
  initialState,
  reducers: {
    auth(state, action: PayloadAction<{ codeGrant: string }>) {},
    unAuth(state) {
      state.isAuthed = false;
      state.identity = undefined;
    },
    identify(
      state,
      action: PayloadAction<{
        identity: string;
        username: string;
        name: string;
      }>,
    ) {
      state.identity = action.payload.identity;
      state.isAuthed = true;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.loading = false;
    },
    doneLoading(state) {
      state.loading = false;
    },
  },
});

export const { actions: ssoActions } = slice;

export const useSsoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ssoSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSsoSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
