// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
// import { paternCompareActions as actions } from '.';

import { selectLane } from 'app/components/lane/Lane/slice/selectors';
import { put, select, takeLeading } from 'redux-saga/effects';
import { paternCompareActions as actions } from '.';

// function* doSomething() {}

export function* paternCompareSaga() {
  yield takeLeading(actions.addPatterns.type, getPatterns);
}

export function* getPatterns() {
  const lane = yield select(selectLane);
  const patterns = yield lane.patterns;
  yield put(actions.addPatterns(patterns));
}
