import {
  Drawer,
  CardContent,
  Typography,
  Collapse,
  Card,
  List,
  Grid,
  BottomNavigation,
  Button,
  Box,
} from '@mui/material';
import React from 'react';
import { BallImage } from './BallImage';
import { useCurrentBallSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentBall, selectCurrentBallDetails } from './slice/selectors';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function BallSelection(props: Props) {
  const { open, setOpen } = props;
  // const dispatch = useDispatch();
  // const makeMove = (laydownChange, targetChange) => {
  //   dispatch(actions.makeMove({ laydownChange, targetChange }));
  //   setOpen(0);
  // };
  const actions = useCurrentBallSlice().actions;
  const inventory = useSelector(selectCurrentBall).inventory;
  const dispatch = useDispatch();
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Button
        variant="contained"
        onClick={() => setOpen(false)}
        fullWidth={true}
        sx={{ position: 'fixed', zIndex: '100' }}
      >
        Close
      </Button>
      <Grid container sx={{ pt: 5 }}>
        {inventory.map(ball => {
          return (
            <Grid item xs={6} sm={4} key={ball.id} display="flex">
              <Button
                onClick={() => {
                  dispatch(actions.setCurrentBall({ id: ball.id }));
                  setOpen(false);
                }}
              >
                <Card sx={{ flexGrow: 0, padding: '10px' }} key={ball.name}>
                  {/* <Box height="10vh" display="flex" sx={{ opacity: '60%' }}> */}
                  <BallImage
                    width="40%"
                    id={ball.id}
                    title={`${ball.manufacturer} ${ball.name}`}
                  />
                  {/* </Box> */}
                  {/* fix me - breaks at large vp due to grid overflow */}
                  <Typography
                    gutterBottom
                    fontSize="1.25rem"
                    variant="h5"
                    textAlign="center"
                    noWrap
                  >
                    {`${ball.manufacturer} ${ball.name}`}
                  </Typography>
                  <CardContent>
                    <Typography>
                      <strong>Core:</strong> {ball.coreType}
                    </Typography>
                    <Typography>
                      <strong>RG: </strong> {ball.rg}
                    </Typography>
                    <Typography>
                      <strong>Surface:</strong>
                      {ball.surface.grit}{' '}
                      {ball.surface.polished ? 'polished' : 'sanded'}
                    </Typography>
                    <Typography>
                      <strong>Frames @ surface:</strong>
                      {ball.countAtSurface}
                    </Typography>
                    <Typography>
                      <strong>Max Diff:</strong> {ball.maxDiff}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Drawer>
  );
}
