import React from 'react';
import { BOARD_WIDTH, PIN_DECK_LENGTH } from '../Lane/constants';
import { useTheme, lighten } from '@mui/material';
import { PatternData } from './types';

const REDISUAL_FACTOR = 1.5;
const NORMAL_SPEED = 14;
let totalOil = 0;
// const BUFF_COLOR = '#5bff8c';

interface Props {
  pattern: PatternData;
  showForwardPattern: boolean;
  showReversePattern: boolean;
  outlineForwardLoads?: boolean;
  outlineReverseLoads?: boolean;
}

export const Pattern = (props: Props) => {
  const theme = useTheme();
  const FORWARD_OIL_COLOR = (theme.palette as any).forwardOil.main;
  const FORWARD_BUFF_COLOR = lighten(
    (theme.palette as any).forwardOil.main,
    0.0,
  );

  const loadsData = props.pattern.loads;

  if (!loadsData) return <></>;

  const REVERSE_OIL_COLOR = (theme.palette as any).reverseOil.main;
  const REVERSE_BUFF_COLOR = lighten(
    (theme.palette as any).reverseOil.main,
    0.0,
  );
  const forwardLength = loadsData.forward.length
    ? loadsData.forward[loadsData.forward.length - 1].stopDistance
    : 0;
  const brushDrop = loadsData.reverse.length
    ? loadsData.reverse[loadsData.reverse.length - 1].startDistance
    : 0;

  let loadsSoFar = 0;
  let rectGroups = 0;
  let oilSoFarByBoard: number[] = [...Array(40).fill(0)];
  let distanceSoFarByBoard: number[] = [...Array(40).fill(0)];

  const genOilLoadBlockForward = ({
    start,
    stop,
    load,
    mics: oil,
    startDistance,
    stopDistance,
    speed,
    showOutline,
  }) => {
    loadsSoFar += load;
    rectGroups++;
    const travelDistance = stopDistance - startDistance;
    const rects: React.JSX.Element[] = [];

    if (showOutline) {
      rects.push(
        <rect
          pointerEvents="none"
          key={`${loadsSoFar}_outline_forward`}
          x={BOARD_WIDTH * (39 - start)}
          y={PIN_DECK_LENGTH + (60 - stopDistance) * 12}
          width={BOARD_WIDTH * (start - stop + 1)}
          height={travelDistance * 12}
          fill={FORWARD_OIL_COLOR}
          strokeWidth={0.5}
          stroke={FORWARD_OIL_COLOR}
          strokeOpacity=".25"
          fillOpacity={0}
        />,
      );
    }
    for (let i = 1; i <= 39; i++) {
      distanceSoFarByBoard[i] += travelDistance;
      if (loadIncludesBoard(i, start, stop)) {
        oilSoFarByBoard[i] += load * oil;
        const residualOilFactor = oilSoFarByBoard[i]
          ? oilSoFarByBoard[i] *
            opcPerMil *
            REDISUAL_FACTOR *
            (NORMAL_SPEED / speed)
          : 0;

        const thisLoadFactor =
          (load ? load * oil * (start - stop + 1) * opcPerMil : 0) *
          (NORMAL_SPEED / speed);
        rects.push(
          <rect
            pointerEvents="none"
            key={`${loadsSoFar}_${i.toString()}_forward`}
            x={BOARD_WIDTH * (39 - i)}
            y={PIN_DECK_LENGTH + (60 - stopDistance) * 12}
            width={BOARD_WIDTH}
            height={travelDistance * 12}
            fill={FORWARD_OIL_COLOR}
            stroke={FORWARD_OIL_COLOR}
            strokeWidth={0}
            fillOpacity={thisLoadFactor + residualOilFactor}
          />,
        );
      } else {
        const residualOilFactor = oilSoFarByBoard[i]
          ? oilSoFarByBoard[i] * opcPerMil * REDISUAL_FACTOR
          : 0;
        rects.push(
          <rect
            pointerEvents="none"
            key={`${loadsSoFar}_${i.toString()}_buf`}
            x={BOARD_WIDTH * (39 - i)}
            // y={PIN_DECK_LENGTH + (60 - forwardLength) * 12}
            y={PIN_DECK_LENGTH + (60 - stopDistance) * 12}
            width={BOARD_WIDTH}
            height={travelDistance * 12}
            fill={FORWARD_BUFF_COLOR}
            //   stroke="green"
            //   strokeWidth={0.1}
            strokeWidth={0}
            strokeOpacity={0}
            fillOpacity={residualOilFactor * (NORMAL_SPEED / speed)}
          />,
        );

        // dissipate?
        // totalForwardOilForBoard[i] -= residualOilFactor;
        // oilSoFarByBoard[i] -= residualOilFactor;
        // console.log(
        //   `${load} ${i} ${totalForwardOilForBoard[i]} ${oilSoFarByBoard[i]}`,
        // );
      }
    }

    return <>{rects}</>;
  };

  const genOilLoadBlockReverse = ({
    start,
    stop,
    load,
    mics: oil,
    startDistance,
    stopDistance,
    speed,
    showOutline,
  }) => {
    loadsSoFar += load;
    const travelDistance = startDistance - stopDistance;
    rectGroups++;
    const rects: React.JSX.Element[] = [];
    if (showOutline) {
      rects.push(
        <rect
          pointerEvents="none"
          key={`${loadsSoFar}_outline_reverse`}
          x={BOARD_WIDTH * (39 - start)}
          y={PIN_DECK_LENGTH + (60 - startDistance) * 12}
          width={BOARD_WIDTH * (start - stop + 1)}
          height={travelDistance * 12}
          strokeWidth={0.5}
          stroke={REVERSE_OIL_COLOR}
          strokeOpacity=".5"
          fillOpacity={0}
        />,
      );
    }
    for (let i = 1; i <= 39; i++) {
      distanceSoFarByBoard[i] += travelDistance;
      if (loadIncludesBoard(i, start, stop)) {
        oilSoFarByBoard[i] += load * oil;
        const residualOilFactor = oilSoFarByBoard[i]
          ? oilSoFarByBoard[i] *
            opcPerMil *
            REDISUAL_FACTOR *
            (NORMAL_SPEED / speed)
          : 0;
        const thisLoadFactor =
          (load ? load * oil * (start - stop + 1) * opcPerMil : 0) *
          (NORMAL_SPEED / speed);
        rects.push(
          <rect
            pointerEvents="none"
            key={`${rectGroups}_${i.toString()}_reverse`}
            x={BOARD_WIDTH * (39 - i)}
            y={PIN_DECK_LENGTH + (60 - startDistance) * 12}
            width={BOARD_WIDTH}
            height={travelDistance * 12}
            fill={REVERSE_OIL_COLOR}
            stroke={REVERSE_OIL_COLOR}
            strokeWidth={0}
            fillOpacity={thisLoadFactor + residualOilFactor}
          />,
        );
      } else {
        const residualOilFactor =
          oilSoFarByBoard[i] > 0
            ? oilSoFarByBoard[i] * opcPerMil * REDISUAL_FACTOR
            : 0;
        rects.push(
          <rect
            pointerEvents="none"
            key={`${loadsSoFar}_${i.toString()}_reverse_buf`}
            x={BOARD_WIDTH * (39 - i)}
            y={PIN_DECK_LENGTH + (60 - startDistance) * 12}
            width={BOARD_WIDTH}
            height={travelDistance * 12}
            fill={REVERSE_BUFF_COLOR}
            fillOpacity={residualOilFactor * (NORMAL_SPEED / speed)}
            //   stroke="black"
            strokeWidth={0}
            strokeOpacity={0}
            // fillOpacity={1}
          />,
        );
      }
      // dissipate?
      // totalReverseOilForBoard[i] -= 50;
      // oilSoFarByBoard[i] -= 50;
      // console.log(
      //   `${load} ${i} ${totalReverseOilForBoard[i]} ${oilSoFarByBoard[i]}`,
      // );
    }

    return <>{rects}</>;
  };

  const rects: React.JSX.Element[] = [];
  let forwardOil = 0;
  let reverseOil = 0;
  let totalForwardOilForBoard = [...Array(40).fill(0)];
  let totalReverseOilForBoard = [...Array(40).fill(0)];
  loadsData.forward.forEach(load => {
    forwardOil += load.load * load.mics * (load.start - load.stop + 1);
    for (let i = 1; i <= 39; i++) {
      if (loadIncludesBoard(i, load.start, load.stop)) {
        totalForwardOilForBoard[i] += load.load * load.mics;
      }
    }
  });
  loadsData.reverse.forEach(load => {
    reverseOil += load.load * load.mics * (load.start - load.stop + 1);
    for (let i = 1; i <= 39; i++) {
      if (loadIncludesBoard(i, load.start, load.stop)) {
        totalReverseOilForBoard[i] += load.load * load.mics;
      }
    }
  });

  // totalOil = forwardOil + reverseOil;
  if (forwardOil + reverseOil > 28000) {
    totalOil = forwardOil + reverseOil;
  } else {
    totalOil = 28000;
  }
  const opcPerMil = (1 / totalOil) * 6;

  console.log(`${totalOil}, ${forwardOil}, ${reverseOil}`);
  for (let i = 0; i < loadsData.forward.length; i++) {
    const loadBlocks = genOilLoadBlockForward({
      ...loadsData.forward[i],
      showOutline: props.outlineForwardLoads,
    });
    if (props.showForwardPattern) {
      rects.push(loadBlocks);
    }
  }

  // oilSoFarByBoard = [...Array(40).fill(0)];

  for (let i = 0; i < loadsData.reverse.length; i++) {
    const loadBlocks = genOilLoadBlockReverse({
      ...loadsData.reverse[i],
      showOutline: props.outlineReverseLoads,
    });
    if (props.showReversePattern) {
      rects.push(loadBlocks);
    }
  }

  return <>{rects}</>;
};

function loadIncludesBoard(board, loadStart, loadStop) {
  return loadStart >= board && board >= loadStop;
}
