import { CardMedia } from '@mui/material';
import * as React from 'react';

interface Props {
  title: string;
  id: string;
  width?: string;
}

export function BallImage(props: Props) {
  const { title, id, width } = props;
  return (
    <CardMedia title={title} sx={{ padding: '3px' }}>
      <img
        width={width ? width : '100%'}
        src={`/images/${id}.png`}
        alt={title}
      />
    </CardMedia>
  );
}
