import { Box, Drawer, TextField } from '@mui/material';
import React from 'react';
import { useFrameNotesSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectFrameNotes } from './slice/selectors';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function NoteEntry(props: Props) {
  const { open, setOpen } = props;
  const actions = useFrameNotesSlice().actions;
  const dispatch = useDispatch();
  const notes = useSelector(selectFrameNotes);
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Box paddingTop={6} padding={2} width="85vw">
        <TextField
          multiline
          fullWidth={true}
          rows={10}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(actions.addNote({ note: event.target.value }));
          }}
          label="Frame Notes"
          placeholder="Add insight"
          value={notes.note}
        ></TextField>
      </Box>
    </Drawer>
  );
}
