import jwtDecode from 'jwt-decode';
function extractIdentity(idToken) {
  const token: any = jwtDecode(idToken);
  const username = (token && (token['email'] as string)) || '';
  const name = `${token.given_name} ${token.family_name}`;

  return { username, name };
}

export default extractIdentity;
