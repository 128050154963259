import React, { MouseEventHandler, TouchEventHandler } from 'react';

import { BOARD_WIDTH, LANE_WIDTH } from './constants';

interface Props {
  id?: string;
  showMarkerLabels?: boolean;
  onClick?: MouseEventHandler<SVGSVGElement>;
  color: string;
  board: number;
  distance: number;
}

export const Marker = (props: Props) => {
  // eslint-disable-line react/prefer-stateless-function

  const markX = (39 - props.board) * BOARD_WIDTH + BOARD_WIDTH / 2;
  const markY = (60 - props.distance) * 12;
  const laneWidthPlusPadding = LANE_WIDTH + 3;
  const line = `M${markX} ${markY} L${laneWidthPlusPadding} ${markY} `;

  return (
    <svg>
      <circle
        cx={markX}
        cy={markY}
        stroke={props.color}
        fill={props.color}
        r={1.0}
      ></circle>
      {props.showMarkerLabels && (
        <>
          <path id={props.id} d={line} stroke={props.color} strokeWidth={1} />

          <g pointerEvents="none">
            <rect
              pointerEvents="all"
              rx="4"
              ry="5"
              strokeOpacity={1}
              stroke={props.color}
              strokeWidth={1}
              fill="white"
              fillOpacity={0}
              opacity={1}
              onClick={props.onClick as any}
              x={laneWidthPlusPadding - 1}
              y={markY - 6}
              width={40}
              height={22}
            />
            <text
              fontFamily="Verdana"
              transform="scale(1,2)"
              fontSize={6}
              x={laneWidthPlusPadding}
              y={markY / 2 + 5}
              width={100}
              height={120}
            >
              {props.board.toFixed(1)} @ {props.distance.toFixed(1)}
            </text>
          </g>
        </>
      )}
    </svg>
  );
};

export default Marker;
