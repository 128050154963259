/**
 *
 * SetSearch
 *
 */
import * as React from 'react';
import { useSetSearchSlice } from './slice';

interface Props {}

export function SetSearch(props: Props) {
  useSetSearchSlice();
  return <div></div>;
}
