/**
 * Created by lsanders on 11/1/16.
 */

export const LANE_WIDTH = 41.5;
export const BOARDS_PER_LANE = 39;
export const BOARD_WIDTH = LANE_WIDTH / BOARDS_PER_LANE;
export const PIN_BASE_DIAMETER = 2.1875;
export const PIN_MAX_DIAMETER = 4.766;
export const PIN_DECK_LENGTH = 34 + 3 / 16;
export const LANE_TOTAL_LENGTH = 60 * 12 + PIN_DECK_LENGTH;
