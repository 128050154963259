import { selectBallDeckExitPointReport } from 'app/components/BallDeckExitPoint/slice/selectors';
import { selectCurrentBallReport } from 'app/components/CurrentBall/slice/selectors';
import { selectFrameNotesReport } from 'app/components/FrameNotes/slice/selectors';
import { selectLoftControlReport } from 'app/components/LoftControl/slice/selectors';
import { selectPinFallReport } from 'app/components/PinFallController/slice/selectors';
import { selectPocketEntryReport } from 'app/components/PocketEntry/slice/selectors';
import { selectSpeedControlReport } from 'app/components/SpeedControl/slice/selectors';
import { selectTargetExecutionReport } from 'app/components/TargetExecution/slice/selectors';
import {
  selectTargetLeftLaneReport,
  selectTargetRightLaneReport,
} from 'app/components/lane/Lane/slice/selectors';

export const COLLECTOR_MAP = {
  pinStatus: selectPinFallReport,
  ballExitPoint: selectBallDeckExitPointReport,
  pocketEntry: selectPocketEntryReport,
  ball: selectCurrentBallReport,
  targetExecution: selectTargetExecutionReport,
  targetLeftLane: selectTargetLeftLaneReport,
  targetRightLane: selectTargetRightLaneReport,
  frameNotes: selectFrameNotesReport,
  speed: selectSpeedControlReport,
  loft: selectLoftControlReport,
};

export type AvailableCollectors = {
  id: string;
  pinStatus: ReturnType<typeof selectPinFallReport>;
  ballExitPoint?: ReturnType<typeof selectBallDeckExitPointReport>;
  pocketEntry?: ReturnType<typeof selectPocketEntryReport>;
  ball?: ReturnType<typeof selectCurrentBallReport>;
  targetExecution?: ReturnType<typeof selectTargetExecutionReport>;
  targetLeftLane?: ReturnType<typeof selectTargetLeftLaneReport>;
  targetRightLane?: ReturnType<typeof selectTargetRightLaneReport>;
  frameNotes?: ReturnType<typeof selectFrameNotesReport>;
  speed?: ReturnType<typeof selectSpeedControlReport>;
  loft?: ReturnType<typeof selectLoftControlReport>;
};

export type FrameSummary = {
  score: number;
  firstBallCount: number;
  secondBallCount?: number;
  thirdBallCount?: number;
  deliveries?: AvailableCollectors[];
};

export type FrameNo = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type BallNo = 1 | 2 | 3;
export interface GameManagerState {
  gameId?: string;
  setId?: string;
  score: number;
  deliveries: AvailableCollectors[];
  enabledCollectors: Array<keyof AvailableCollectors>;
  fullRack: boolean;
  currentBall: BallNo;
  currentFrame: FrameNo;
  editBall?: BallNo;
  editFrame?: FrameNo;
  editId?: string;
  complete: boolean;
  frames: FrameSummary[];
  completedGames: FrameSummary[][];
}
