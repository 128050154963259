import { Drawer, CardContent, Typography, Collapse, Card } from '@mui/material';
import React from 'react';
import { BallImage } from './BallImage';
import { useCurrentBallSlice } from './slice';
import { useSelector } from 'react-redux';
import { selectCurrentBallDetails } from './slice/selectors';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function DetailView(props: Props) {
  const { open, setOpen } = props;
  // const dispatch = useDispatch();
  // const makeMove = (laydownChange, targetChange) => {
  //   dispatch(actions.makeMove({ laydownChange, targetChange }));
  //   setOpen(0);
  // };
  useCurrentBallSlice();
  const ball = useSelector(selectCurrentBallDetails);
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Card sx={{ width: '85vw', padding: '20px' }}>
        <BallImage
          id={ball.id}
          title={`${ball.manufacturer} ${ball.name}`}
          width="60%"
        />
        {/* fix me - breaks at large vp due to grid overflow */}
        <Typography gutterBottom variant="h5">
          {`${ball.manufacturer} ${ball.name}`}
        </Typography>
        <CardContent>
          <Typography variant="h5">
            <strong>Core</strong>
          </Typography>
          <Typography component="div" noWrap gutterBottom>
            {ball.coreType}
          </Typography>
          <Typography variant="h5">
            <strong>RG</strong>
          </Typography>
          <Typography component="div" noWrap gutterBottom>
            {ball.rg}
          </Typography>
          <Typography variant="h5" component="div" noWrap>
            <strong>Surface</strong>
          </Typography>
          <Typography gutterBottom>
            {ball.surface.grit} {ball.surface.polished ? 'polished' : 'sanded'}
          </Typography>
          <Typography variant="h5" component="div" noWrap>
            <strong>Frames @ surface</strong>
          </Typography>
          <Typography gutterBottom>{ball.countAtSurface}</Typography>
          <Typography variant="h5">
            <strong>Max Differential</strong>
          </Typography>
          <Typography component="div" noWrap gutterBottom>
            {ball.maxDiff}
          </Typography>
        </CardContent>
      </Card>
    </Drawer>
  );
}
