import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { paternCompareSaga } from './saga';
import { PaternCompareState } from './types';
import { useLaneSlice } from 'app/components/lane/Lane/slice';
import { PatternData } from 'app/components/lane/Pattern/types';
export const initialState: PaternCompareState = {
  patterns: [],
  patternsToCompare: [],
};

const slice = createSlice({
  name: 'paternCompare',
  initialState,
  reducers: {
    addPatterns(state, action: PayloadAction<PatternData[]>) {
      state.patterns = action.payload;
    },
    setSelectedPatterns(state, action: PayloadAction<PatternData[]>) {
      state.patternsToCompare = action.payload;
    },
  },
});

export const { actions: paternCompareActions } = slice;

export const usePaternCompareSlice = () => {
  useLaneSlice();
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: paternCompareSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePaternCompareSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
