/**
 *
 * ScoreLine
 *
 */
import * as React from 'react';
import { Frame } from './Frame';
import { Box, Stack } from '@mui/material';
import { useGameManagerSlice } from '../GameManager/slice';
import { useSelector } from 'react-redux';
import { selectFrame, selectGameManager } from '../GameManager/slice/selectors';
import { FrameLabel } from './FrameLabel';
import { FrameNo } from '../GameManager/slice/types';
import { GameLine } from './GameLine';

interface Props {}

export function ScoreLine(props: Props) {
  useGameManagerSlice();
  const currentFrame = useSelector(selectGameManager).currentFrame;
  const frames: React.JSX.Element[] = [];
  const refs: any = React.useMemo(() => [], []);
  const data: any = [];
  for (let i = 0; i < 10; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const selector = useSelector(state => selectFrame(state, i));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = React.useRef(null);
    refs.push(ref);
    data.push({
      ...selector,
      key: `frame ${i}`,
      frameNo: i + 1,
      ref,
      isCurrent: i + 1 === currentFrame,
    });
  }

  React.useEffect(() => {
    refs[currentFrame - 1].current.scrollIntoView();
  }, [currentFrame, refs]);
  return (
    <Stack
      direction={'row'}
      // sx={{ width: '100%' }}
      // maxWidth={'100%'}
      overflow={'scroll auto'}
    >
      <GameLine frames={data} />
    </Stack>
  );
}
