import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { BowlingSetState } from './types';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { bowlingSetSaga } from './saga';
import { v4 } from 'uuid';

export const initialState: BowlingSetState = {
  initializing: true,
  type: 'appTesting',
  event: '',
  startingLane: -1,
  inProgress: false,
};

const slice = createSlice({
  name: 'bowlingSet',
  initialState,
  extraReducers: builder => {
    builder.addCase(
      gameManagerActions.completeSet,
      (state, action: PayloadAction<any>) => {
        return { ...initialState };
      },
    );
  },
  reducers: {
    start(
      state,
      action: PayloadAction<
        Partial<BowlingSetState> & { target: number; laydown: number }
      >,
    ) {
      state.id = action.payload.id || v4();
      state.event = action.payload.event || '';
      state.startingLane = action.payload.startingLane || -1;
      state.type = action.payload.type || 'appTesting';
      state.inProgress = true;
      state.startTime = new Date().valueOf();
      state.initializing = false;
    },
    complete(state) {
      state.finishTime = new Date().valueOf();
    },
    abandon(state) {
      return { ...initialState };
    },
    restoreSet(
      state,
      action: PayloadAction<{
        state: BowlingSetState;
      }>,
    ) {
      return action.payload.state;
    },
    updateApiStart(state) {},
    updateApiSuccess(state, action: PayloadAction<any>) {},
    updateApiRetry(state, action: PayloadAction<any>) {},
  },
});

export const { actions: bowlingSetActions } = slice;

export const useBowlingSetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: bowlingSetSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useBowlingSetSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
