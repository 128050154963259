/**
 *
 * LanePinSpot
 *
 */

import React from 'react';
import { PIN_BASE_DIAMETER, PIN_MAX_DIAMETER } from '../Lane/constants';
import { PinState } from '../LanePinDeck';

interface Props extends PinState {
  x: number;
  y: number;
}
export const LanePinSpot = (props: Props) => {
  const { x, y, up, color, onClick, active = true } = props;
  const svgLength = PIN_MAX_DIAMETER / 2;
  const pinOffset = PIN_MAX_DIAMETER / 2;
  const transLoc = `translate(${x - pinOffset}, ${y - pinOffset})`;
  const boundaryCircle = onClick ? (
    <circle
      cx={svgLength}
      cy={svgLength}
      r={PIN_BASE_DIAMETER * 2}
      fillOpacity={0}
      onClick={onClick}
      pointerEvents={'all'}
    />
  ) : (
    <></>
  );
  return (
    <svg>
      <g transform={transLoc}>
        {active && boundaryCircle}
        <circle
          cx={svgLength}
          cy={svgLength}
          r={PIN_BASE_DIAMETER / 2}
          opacity={active ? '.2' : '.05'}
        />
        <circle
          cx={svgLength}
          cy={svgLength}
          r={PIN_MAX_DIAMETER / 2}
          fill={up ? color : ''}
          opacity={active ? '.2' : '.05'}
        />
      </g>
    </svg>
  );
};

export default LanePinSpot;
