/**
 *
 * SpeedControl
 *
 */
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { SpeedDrawer } from './SpeedDrawer';
import { useSelector } from 'react-redux';
import { selectSpeedControl } from './slice/selectors';
import { useSpeedControlSlice } from './slice';

interface Props {}

export function SpeedControl(props: Props) {
  const [open, setOpen] = React.useState(false);
  const speedControl = useSelector(selectSpeedControl);
  useSpeedControlSlice();
  return (
    <>
      <SpeedDrawer open={open} setOpen={setOpen} />
      <Box padding={1}>
        <Typography align="center" fontSize="0.75rem">
          Speed
        </Typography>
        <Button
          color={
            speedControl.execution === undefined ||
            speedControl.target === speedControl.execution
              ? undefined
              : 'error'
          }
          fullWidth={true}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          {speedControl.execution || speedControl.target}
        </Button>
      </Box>
    </>
  );
}
