import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.gameManager || initialState;

export const selectGameManager = createSelector([selectSlice], state => state);
export const selectFrame = createSelector(
  [selectSlice, (state: RootState, frameNo: number) => frameNo],
  (state, frameNo) => state.frames[frameNo],
);
