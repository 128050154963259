import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { Speed, SpeedControlState } from './types';
import { selectFrameNotesReport } from 'app/components/FrameNotes/slice/selectors';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectSpeedControlReport } from './selectors';
import {
  GameManagerState,
  AvailableCollectors,
} from 'app/components/GameManager/slice/types';

export const initialState: SpeedControlState = {
  target: 'Normal',
};

const slice = createSlice({
  name: 'speedControl',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        gameManagerActions.restoreGame,
        (
          state,
          action: PayloadAction<{
            state: GameManagerState;
            lastDelivery: AvailableCollectors;
          }>,
        ) => {
          console.log('restore game - speed');
          if (action.payload.lastDelivery.speed) {
            console.log('restore game - speed - done');
            state.target = action.payload.lastDelivery.speed.target as any;
          }
        },
      )
      .addCase(
        gameManagerActions.newFrame,
        (state, action: PayloadAction<any>) => {
          state.execution = state.target;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.execution;
        },
      )
      .addCase(
        gameManagerActions.setEditDelivery,
        (
          state,
          action: PayloadAction<{
            speed?: ReturnType<typeof selectSpeedControlReport>;
          }>,
        ) => {
          state.execution = action.payload.speed?.execution;
        },
      )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.execution = state.save;
        },
      );
  },
  reducers: {
    setTarget(state, action: PayloadAction<string>) {
      state.target = action.payload as Speed;
    },
    setExecution(state, action: PayloadAction<string>) {
      state.execution = action.payload as Speed;
    },
  },
});

export const { actions: speedControlActions } = slice;

export const useSpeedControlSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSpeedControlSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
