/**
 *
 * CurrentBall
 *
 */
import * as React from 'react';
import { BallImage } from './BallImage';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { DetailView } from './DetailView';
import { useCurrentBallSlice } from './slice';
import { useSelector } from 'react-redux';
import { selectCurrentBallDetails } from './slice/selectors';
import { BallSelection } from './BallSelection';
interface Props {}

export function CurrentBall(props: Props) {
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [selectionOpen, setSelectionOpen] = React.useState(false);
  useCurrentBallSlice();
  const ball = useSelector(selectCurrentBallDetails);
  return (
    <Box margin={1}>
      <DetailView open={detailOpen} setOpen={setDetailOpen} />
      <BallSelection open={selectionOpen} setOpen={setSelectionOpen} />
      <Card>
        <BallImage id={ball.id} title={`${ball.manufacturer} ${ball.name}`} />
        {/* fix me - breaks at large vp due to grid overflow */}
        <Typography
          gutterBottom
          fontSize="1rem"
          variant="h5"
          textAlign="center"
        >
          {ball.manufacturer} {ball.name}
        </Typography>
        <CardActions sx={{ padding: 0, margin: 0 }}>
          <IconButton
            onClick={() => setSelectionOpen(true)}
            size="small"
            sx={{ margin: 0, padding: 0 }}
          >
            <ChangeCircleIcon />
          </IconButton>
          <IconButton
            onClick={() => setDetailOpen(true)}
            size="small"
            sx={{ margin: 0, padding: 0 }}
          >
            <VisibilityIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
}
