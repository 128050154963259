/**
 *
 * LaneArrow
 *
 */

import React from 'react';

//import styles from './styles.css';
interface Props {
  x: number;
  y: number;
  height: number;
  width: number;
}

export const LaneArrow = (props: Props) => {
  const transLoc = `translate(${props.x - props.width / 2}, ${props.y})`;
  const points = `0,${props.height} ${props.width / 2},0 ${props.width},${
    props.height
  }`;
  return (
    <svg pointerEvents="none">
      <g transform={transLoc}>
        <polygon points={points} fill="black" />
      </g>
    </svg>
  );
};

export default LaneArrow;
