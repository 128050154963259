import { LoadsData } from 'app/components/lane/Pattern/types';

export function parseRawLoads(rawLoadsStr): LoadsData[] {
  const rawLoads: [] = rawLoadsStr.split('\n');
  const loads: any[] = [];
  try {
    for (let i = 0; i < rawLoads.length; i += 10) {
      const loadData = {
        num: parseInt(rawLoads[i]),
        start: getBoard(rawLoads[i + 1]),
        stop: getBoard(rawLoads[i + 2]),
        load: parseInt(rawLoads[i + 3]),
        mics: parseInt(rawLoads[i + 4]),
        speed: parseInt(rawLoads[i + 5]),
        buf: parseInt(rawLoads[i + 6]),
        tank: rawLoads[i + 7],
        startDistance: parseFloat((rawLoads[i + 8] as string).split(' → ')[0]),
        stopDistance: parseFloat((rawLoads[i + 8] as string).split(' → ')[1]),
        toil: parseFloat((rawLoads[i + 9] as string).replace(',', '')),
      };
      loads.push(loadData);
    }
  } catch (e) {
    console.log(e);
    return [];
  }
  return loads;
}

export function getBoard(s: string) {
  if (typeof s === 'string') {
    if (s.toUpperCase().includes('L')) {
      return 40 - parseInt(s);
    }
  }
  return parseInt(s);
}
