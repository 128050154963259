import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpeedControlSlice } from './slice';
import { selectSpeedControl } from './slice/selectors';
import { Speed } from './slice/types';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function SpeedDrawer(props: Props) {
  const { open, setOpen } = props;
  const actions = useSpeedControlSlice().actions;
  const dispatch = useDispatch();
  const speed = useSelector(selectSpeedControl);
  const handleChange = (event: SelectChangeEvent) => {
    dispatch(actions.setTarget(event.target.value as string));
  };
  const handleExecution = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    dispatch(actions.setExecution(value as Speed));
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Box paddingTop={6} padding={2} margin={2} width="75vw">
        <FormControl fullWidth>
          <InputLabel id="target-speed-label">
            <Typography>Target Speed</Typography>
          </InputLabel>
          <Select
            labelId="target-speed-label"
            id="target-speed-select"
            value={speed.target}
            onChange={handleChange}
            label="Target Speed"
          >
            <MenuItem value="Faster">Faster</MenuItem>
            <MenuItem value="Fast">Fast</MenuItem>
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="Slow">Slow</MenuItem>
            <MenuItem value="Slower">Slower</MenuItem>
          </Select>
        </FormControl>
        <Box margin={2}>
          <Typography>Execution</Typography>
          <ToggleButtonGroup
            exclusive
            fullWidth={true}
            value={speed.execution}
            onChange={handleExecution}
            color="primary"
            orientation="vertical"
          >
            <ToggleButton value="Faster">Faster</ToggleButton>
            <ToggleButton value="Fast">Fast</ToggleButton>
            <ToggleButton value="Normal">Normal</ToggleButton>
            <ToggleButton value="Slow">Slow</ToggleButton>
            <ToggleButton value="Slower">Slower</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Drawer>
  );
}
