/**
 *
 * ScoreLine
 *
 */
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  colors,
  useTheme,
} from '@mui/material';
import { green } from '@mui/material/colors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGameManagerSlice } from '../GameManager/slice';
import { AvailableCollectors, FrameNo } from '../GameManager/slice/types';
import { selectGameManager } from '../GameManager/slice/selectors';
import { PinState } from '../lane/LanePinDeck';

export interface Props {
  firstBallCount?: number;
  secondBallCount?: number;
  thirdBallCount?: number;
  score?: number;
  isCurrent?: boolean;
  frameNo: FrameNo;
  size?: number;
  deliveries?: AvailableCollectors[];
}

export const Frame = React.forwardRef((props: Props, ref) => {
  const {
    firstBallCount,
    secondBallCount,
    thirdBallCount,
    score,
    isCurrent,
    frameNo: frame,
    size,
  } = props;
  const SPARE_SVG = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 10 L10 -1' stroke-width='1' stroke='black' /></svg>")`;
  const STRIKE_SVG = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 10 L10 -1 M0 0 L10 10' stroke-width='1' stroke='black' /></svg>")`;

  const isStrike = firstBallCount === 10;
  const isSpare =
    !isStrike && (firstBallCount || 0) + (secondBallCount || 0) === 10;

  const dispatch = useDispatch();
  const actions = useGameManagerSlice().actions;
  const game = useSelector(selectGameManager);
  const editBall = game.editFrame && game.editFrame === frame && game.editBall;
  const theme = useTheme();
  const editColor = theme.palette.secondary.light;

  const frameSize = size || 48;
  return (
    <Grid
      container
      height={frameSize}
      width={frame === 10 ? frameSize * 1.5 : frameSize}
      // width={30}
      borderColor="black"
      border={2}
      borderRight={frame === 10 ? 2 : 0}
      component={Box}
      ref={ref}
      sx={{
        backgroundColor: isCurrent ? green[100] : '',
      }}
    >
      <Grid
        item
        xs={frame !== 10 ? 6 : 4}
        component="div"
        onClick={() => {
          if (game.currentFrame < frame || isStrike) return;
          if (game.editFrame) return;
          dispatch(actions.setEdit({ frame: frame, ball: 1 }));
        }}
        sx={{
          background:
            frame === 10 && firstBallCount && isStrike ? STRIKE_SVG : undefined,
          backgroundColor: editBall === 1 && !isStrike ? editColor : undefined,
        }}
      >
        <Typography
          textAlign="center"
          sx={{ fontSize: `${(size || 48) / 48}rem` }}
        >
          {isStrike ? '' : firstBallCount}
        </Typography>
      </Grid>
      <Grid
        item
        xs={frame !== 10 ? 6 : 4}
        height="50%"
        flexGrow={1}
        borderColor="blue"
        border={2}
        component="div"
        onClick={event => {
          if (game.currentFrame < frame) return;
          if (game.editFrame) return;
          dispatch(actions.setEdit({ frame: frame, ball: isStrike ? 1 : 2 }));
        }}
        zIndex={100}
        sx={{
          background:
            frame !== 10
              ? isStrike
                ? STRIKE_SVG
                : isSpare
                ? SPARE_SVG
                : undefined
              : firstBallCount && secondBallCount
              ? firstBallCount + secondBallCount === 10
                ? SPARE_SVG
                : firstBallCount + secondBallCount === 20
                ? STRIKE_SVG
                : undefined
              : undefined,
          backgroundColor: editBall === 1 && isStrike ? editColor : undefined,
          top: -2,
          left: 2,
          position: 'relative',
        }}
      >
        <Typography textAlign="center">
          {frame !== 10
            ? isStrike
              ? ''
              : isSpare
              ? ''
              : secondBallCount
            : firstBallCount && secondBallCount
            ? firstBallCount + secondBallCount === 10
              ? ''
              : firstBallCount + secondBallCount === 20
              ? ''
              : secondBallCount
            : secondBallCount}
        </Typography>
      </Grid>
      {frame === 10 && (
        <Grid
          // visibility={frame === 10 ? 'visible' : 'hidden'}
          item
          xs={frame !== 10 ? 0 : 4}
          height="50%"
          flexGrow={1}
          borderColor="blue"
          border={2}
          component="div"
          onClick={event => {
            if (game.currentFrame < frame) return;
            if (game.editFrame) return;
            dispatch(actions.setEdit({ frame: frame, ball: isStrike ? 1 : 2 }));
          }}
          zIndex={100}
          sx={{
            background:
              thirdBallCount &&
              thirdBallCount === 10 &&
              (firstBallCount || 0) + (secondBallCount || 0) > 10
                ? STRIKE_SVG
                : thirdBallCount &&
                  (firstBallCount || 0) + (secondBallCount || 0) === 10 &&
                  thirdBallCount === 10
                ? SPARE_SVG
                  ? STRIKE_SVG
                  : undefined
                : undefined,
            backgroundColor: editBall === 1 && isStrike ? editColor : undefined,
            top: -2,
            left: 2,
            position: 'relative',
          }}
        >
          <Typography
            textAlign="center"
            sx={{ fontSize: `${(size || 48) / 48}rem` }}
          >
            {thirdBallCount && thirdBallCount !== 10 ? thirdBallCount : ''}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          sx={{ fontSize: `${(size || 48) / 48}rem` }}
        >
          {score}
        </Typography>
      </Grid>
    </Grid>
  );
});
