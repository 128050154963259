import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLane } from '../Lane/slice/selectors';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { laneActions } from '../Lane/slice';

interface Props {
  actions: typeof laneActions;
  lane: ReturnType<typeof selectLane>;
}

export default function PatternDropDown(props: Props) {
  const { lane, actions: laneActions } = props;

  const [pattern, setPattern] = useState(lane.selectedPattern);

  const dispatch = useDispatch();
  const onPatternChange = (event, newValue) => {
    setPattern(newValue);
    if (newValue?.name) {
      dispatch(laneActions.setPattern(newValue.name));
    } else {
      dispatch(laneActions.setPattern(''));
    }
  };

  const options = lane.patterns.map(option => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  React.useEffect(() => {}, [lane.showForwardPattern, lane.showReversePattern]);

  return (
    <Box padding={1}>
      <Stack spacing={1}>
        <Autocomplete
          value={pattern}
          id="combo-box-patterns"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
          )}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <TextField {...params} label="Pattern" />
            </div>
          )}
          onChange={onPatternChange}
        />

        {pattern && (
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={lane.showForwardPattern} />}
              onChange={(e, checked) =>
                dispatch(laneActions.setShowForwardPattern(checked))
              }
              label="Show Forward"
            />
            <FormControlLabel
              control={<Switch checked={lane.showReversePattern} />}
              onChange={(e, checked) =>
                dispatch(laneActions.setShowReversePattern(checked))
              }
              label="Show Reverse"
            />
          </FormGroup>
        )}
      </Stack>
    </Box>
  );
}
