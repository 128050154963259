/**
 *
 * StatsSpareLeaves
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';

interface Props {
  frames: FrameSummary[][];
}

export function StatsSpareLeaves(props: Props) {
  // const { frames } = props;
  // const combos = new Map();
  // frames.forEach(game => {
  //   game.forEach(frame => {
  //     if (frame.firstBallCount !== 10) {
  //       const left = (frame.deliveries as any)[0].pinStatus?.pinStatus
  //         .filter(pin => pin.up)
  //         .map(pin => pin.pin)
  //         .join();
  //       if (combos.has(left)) {
  //         combos.set(left, {
  //           ...combos.get(left),
  //           left: combos.get(left).left + 1,
  //         });
  //       } else {
  //         combos.set(left, { left: 1, converted: 0 });
  //       }
  //       if (frame.firstBallCount + (frame.secondBallCount || 0) === 10) {
  //         combos[left].converted++;
  //       }
  //     }
  //   });
  // });
  // combos.forEach((key, value) => {
  //   console.log(key);
  //   console.log(value);
  // });

  return <></>;
}
