import { listSets } from 'api/set';
import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { setSearchActions as actions } from '.';

// function* doSomething() {}

export function* setSearchSaga() {
  yield takeLatest(actions.listSets.type, apiListSets);
}

export function* apiListSets() {
  yield put(actions.listSetsApiStart());
  yield call(listResource);
}
export function* listResource() {
  const apiResponse = yield call(listSetApi);
  //   const apiResponse = JSON.parse(`{
  //     "result": "success",
  //     "sets": [
  //         {
  //             "type": "appTesting",
  //             "event": "testing",
  //             "date": "2024-02-17",
  //             "startTime": "2024-02-17T20:55:53.663Z",
  //             "id": "47aade4d-e86a-4e5b-a180-5abbb7521036",
  //             "userId": "eeefc60d-c645-4ef2-888c-acb3a5223f43",
  //             "userFirstName": "Larry",
  //             "userLastName": "Sanders",
  //             "created": "2024-02-17T20:55:54.514Z",
  //             "updated": "2024-02-17T20:55:54.514Z"
  //         },
  //         {
  //             "type": "appTesting",
  //             "event": "testing 2",
  //             "date": "2024-02-17",
  //             "startTime": "2024-02-17T20:55:53.663Z",
  //             "id": "17aade4d-e86a-4e5b-a180-5abbb7521036",
  //             "userId": "1eefc60d-c645-4ef2-888c-acb3a5223f43",
  //             "userFirstName": "Larry",
  //             "userLastName": "Sanders",
  //             "created": "2024-02-17T20:55:54.514Z",
  //             "updated": "2024-02-17T20:55:54.514Z"
  //         }
  //     ]
  // }`);

  yield put(actions.listSetsApiSuccess(apiResponse.sets));
}
export function* listSetApi() {
  while (true) {
    try {
      const apiResponse = yield call(listSets);
      return apiResponse;
    } catch (error) {
      yield put(actions.listSetsApiRetry(error));
      yield delay(20000);
    }
  }
}
