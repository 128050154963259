/**
 *
 * SpeedControl
 *
 */
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { LoftDrawer } from './LoftDrawer';
import { useSelector } from 'react-redux';
import { selectLoftControl } from './slice/selectors';
import { useLoftControlSlice } from './slice';

interface Props {}

export function LoftControl(props: Props) {
  const [open, setOpen] = React.useState(false);
  const loftControl = useSelector(selectLoftControl);
  useLoftControlSlice();
  return (
    <>
      <LoftDrawer open={open} setOpen={setOpen} />
      <Box padding={1}>
        <Typography align="center" fontSize="0.75rem">
          Loft
        </Typography>
        <Button
          color={
            loftControl.execution === undefined ||
            loftControl.target === loftControl.execution
              ? undefined
              : 'error'
          }
          fullWidth={true}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          {loftControl.execution || loftControl.target} ft
        </Button>
      </Box>
    </>
  );
}
