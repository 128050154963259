/**
 *
 * BallDeckExitPoint
 *
 */
import {
  ToggleButtonGroup,
  ToggleButton,
  SxProps,
  Box,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { ballDeckExitPointActions, useBallDeckExitPointSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectBallDeckExitPoint } from './slice/selectors';
import { usePinFallControllerSlice } from '../PinFallController/slice';
import { useGameManagerSlice } from '../GameManager/slice';
import { selectGameManager } from '../GameManager/slice/selectors';

interface Props {}
export function BallDeckExitPoint(props: Props) {
  const ballDeckExitPointSelector = useSelector(selectBallDeckExitPoint);
  const exitPoint = ballDeckExitPointSelector.ballExitPoint;

  const dispatch = useDispatch();

  const handleExit = (
    event: React.MouseEvent<HTMLElement>,
    newExitPoint: string | null,
  ) => {
    dispatch(ballDeckExitPointActions.setBallDeckExitPoint(newExitPoint));
  };

  useBallDeckExitPointSlice();
  useGameManagerSlice();

  const game = useSelector(selectGameManager);
  const fullRack = game.fullRack;

  const valueUnsetIndicatorSx: Partial<SxProps> = {
    borderTop: 1,
    borderTopColor: exitPoint ? 'green' : 'red',
  };

  return (
    <Box
      paddingBottom={0}
      marginBottom={0}
      sx={{ visibility: fullRack ? 'visible' : 'hidden' }}
    >
      <Typography fontSize=".75rem">Deck Exit Point</Typography>
      <ToggleButtonGroup
        exclusive
        fullWidth={true}
        value={exitPoint}
        onChange={handleExit}
        color="primary"
        sx={valueUnsetIndicatorSx}
      >
        <ToggleButton value="1"></ToggleButton>
        <ToggleButton value="2"></ToggleButton>
        <ToggleButton value="3"></ToggleButton>
        <ToggleButton value="4"></ToggleButton>
        <ToggleButton value="5"></ToggleButton>
        <ToggleButton value="6"></ToggleButton>
        <ToggleButton value="7"></ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
