import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { gameManagerSaga } from './saga';
import {
  AvailableCollectors,
  BallNo,
  FrameNo,
  GameManagerState,
} from './types';
import { v4 } from 'uuid';
import { getCount, isStrike } from './util';
import { bowlingSetActions } from 'app/components/BowlingSet/slice';
import { NotAuthorizedException } from '@aws-sdk/client-cognito-identity';

export const initialState: GameManagerState = {
  fullRack: true,
  currentBall: 1,
  currentFrame: 1,
  frames: [],
  deliveries: [],
  enabledCollectors: [
    'pinStatus',
    'ballExitPoint',
    'pocketEntry',
    'ball',
    'targetExecution',
    'targetLeftLane',
    'targetRightLane',
    'frameNotes',
    'speed',
    'loft',
  ],
  score: 0,
  complete: false,
  completedGames: [],
};

const slice = createSlice({
  name: 'gameManager',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        bowlingSetActions.start,
        (state, action: ReturnType<typeof bowlingSetActions.start>) => {
          state.setId = action.payload.id as string;
          return state;
        },
      )
      .addCase(bowlingSetActions.abandon, state => {
        return { ...initialState };
      });
  },
  reducers: {
    restoreGame(
      state,
      action: PayloadAction<{
        state: GameManagerState;
        lastDelivery: AvailableCollectors;
      }>,
    ) {
      return action.payload.state;
    },
    newGame(state, action: PayloadAction<{ setId?: string }>) {
      if (state.complete) {
        state.completedGames.push(state.frames);
      }
      state.fullRack = true;
      state.complete = false;
      state.frames = [];
      state.deliveries = [];
      state.currentBall = initialState.currentBall;
      state.currentFrame = initialState.currentFrame;
      state.gameId = v4();
      // state.setId = action.payload.setId;
    },
    setEdit(state, action: PayloadAction<{ frame: FrameNo; ball: BallNo }>) {
      state.editBall = action.payload.ball;
      state.editFrame = action.payload.frame;
      const frame = state.frames[(action.payload.frame || 1) - 1];
      const delivery =
        frame.deliveries && frame.deliveries[(action.payload.ball || 1) - 1];
      state.editId = delivery?.id;
    },
    record(state, action: PayloadAction<AvailableCollectors | undefined>) {
      // extra collected data
      if (state.editBall && action.payload) {
        const editDelivery: AvailableCollectors = state.deliveries.find(
          d => d.id === state.editId,
        ) as AvailableCollectors;
        state.deliveries = state.deliveries.map(d =>
          d.id === state.editId
            ? ({
                ...action.payload,
                id: state.editId,
              } as any)
            : d,
        );
        const report = action.payload;
        const originalCount = editDelivery?.pinStatus.pinStatus.filter(
          p => !p.up,
        ).length;
        const newCount = action.payload.pinStatus.pinStatus.filter(
          p => !p.up,
        ).length;
        if (originalCount !== newCount && state.editBall === 1) {
          if (originalCount === 10) {
            const newDelivery = {};
            Object.keys(editDelivery).forEach(key => {
              newDelivery[key] = editDelivery[key];
            });
            newDelivery['pinStatus'] = {
              pinStatus: report['pinStatus'].pinStatus.map(p => ({
                ...p,
                active: p.up,
              })),
            };
            newDelivery['id'] = v4();
            addDelivery(state, newDelivery, state.editId);
          } else if (newCount === 10) {
            removeDelivery(state, state.editId);
          } else {
            const nextDeliveryIdx =
              state.deliveries.findIndex(d => d.id === state.editId) + 1;
            state.deliveries[nextDeliveryIdx].pinStatus.pinStatus =
              state.deliveries[nextDeliveryIdx].pinStatus.pinStatus.map(p => ({
                ...p,
                active:
                  (
                    report.pinStatus.pinStatus.find(
                      op => op.pin === p.pin,
                    ) as any
                  ).up || false,
              }));
          }
        }
      } else if (action.payload) {
        state.deliveries.push({ ...action.payload, id: v4() });
      }
      state.editBall = undefined;
      state.editFrame = undefined;

      const counts: number[] = state.deliveries.map(deliveries =>
        getCount(deliveries.pinStatus.pinStatus),
      );

      let ballIndex = 0;
      let score = 0;
      const isStrike = index => counts[index] === 10;
      const isSpare = index => counts[index] + counts[index + 1] === 10;
      const sumOfBallsInFrame = index =>
        counts[index] + (counts[index + 1] || 0);
      const spareBonus = index => counts[index + 2] || 0;
      const strikeBonus = index =>
        (counts[index + 1] || 0) + (counts[index + 2] || 0);
      const isFrameStarted = index => counts[index] !== undefined;
      const isFrameComplete = index =>
        (state.currentFrame === 10 &&
          (isStrike(index) || isSpare(index)) &&
          counts[index + 2] !== undefined) ||
        (state.currentFrame === 10 &&
          counts[index + 1] !== undefined &&
          !isStrike(index) &&
          !isSpare(index)) ||
        (isStrike(index) && state.currentFrame !== 10) ||
        (state.currentFrame !== 10 &&
          counts[index] !== undefined &&
          counts[index + 1] !== undefined);

      const scoreInFrames: number[] = [];
      for (let frame = 0; frame < 10; frame++) {
        if (!isFrameStarted(ballIndex)) {
          break;
        } else if (frame + 1 === 10) {
          if (isFrameComplete(ballIndex)) {
            state.complete = true;
          }
          state.currentBall =
            counts[ballIndex] === undefined
              ? 1
              : counts[ballIndex + 1] === undefined
              ? 2
              : 3;
        } else if (isFrameComplete(ballIndex)) {
          state.currentBall = 1;
          state.currentFrame = (frame + 2) as any;
        } else {
          state.currentBall++;
          // state.currentBall = 2;
          state.currentFrame = (frame + 1) as any;
        }

        if (isStrike(ballIndex)) {
          score += 10 + strikeBonus(ballIndex);
          ballIndex++;
          state.frames[frame] = {
            score: score,
            firstBallCount: 10,
            secondBallCount: frame === 9 ? counts[ballIndex] : undefined,
            thirdBallCount: frame === 9 ? counts[ballIndex + 1] : undefined,
            deliveries: [],
          };
          state.frames[frame].deliveries?.push(state.deliveries[ballIndex - 1]);
        } else if (isSpare(ballIndex)) {
          score += 10 + spareBonus(ballIndex);
          ballIndex += 2;
          state.frames[frame] = {
            score: score,
            firstBallCount: counts[ballIndex - 2],
            secondBallCount: counts[ballIndex - 1],
            thirdBallCount: frame === 9 ? counts[ballIndex] : undefined,
            deliveries: [],
          };
          state.frames[frame].deliveries?.push(state.deliveries[ballIndex - 2]);
          state.frames[frame].deliveries?.push(state.deliveries[ballIndex - 1]);
        } else {
          score += sumOfBallsInFrame(ballIndex);
          ballIndex += 2;
          state.frames[frame] = {
            score: score,
            firstBallCount: counts[ballIndex - 2],
            secondBallCount: counts[ballIndex - 1],
            thirdBallCount: frame === 9 ? counts[ballIndex] : undefined,
            deliveries: [],
          };
          state.frames[frame].deliveries?.push(state.deliveries[ballIndex - 2]);
          state.frames[frame].deliveries?.push(state.deliveries[ballIndex - 1]);
        }
        scoreInFrames.push(score);
      }

      state.score = score;
      if (state.deliveries && state.deliveries.length) {
        const pinStatus =
          state.deliveries[state.deliveries.length - 1].pinStatus;
        if (state.currentBall === 1) {
          state.fullRack = true;
        } else {
          state.fullRack = pinStatus.pinStatus.find(p => p.up) ? false : true;
        }
      }
      // const totalDeliveries = state.deliveries.length;
      //   for (let b = 0; b < totalDeliveries; b++) {
      //     const count = getCount(totalDeliveries[b].pinStatus.pinStatus)
      //     if (ballIndex === 1 && !isStrike(totalDeliveries[b])) {
      //       draftScore += 10 + strikeBonus(b);
      //       frameIndex++;

      //       if (!isStrikeOrSpare) {
      //       count = count - getCount(state.deliveries[b-1].pinStatus.pinStatus)
      //       draftScore += count;
      //       frameIndex++;
      //       ballIndex = 1;
      //       continue;
      //       } else {

      //       }
      //     }

      //     if (ballIndex !== 1 && count === 10 && b + 1 < totalDeliveries) {
      //       draftScore +=
      //         10 + getCount((state.deliveries[b + 1] as any).pinStatus);
      //       frameIndex++;
      //       ballIndex = 1;
      //     } else if (ballIndex === 1 && count === 10 && b + 2 < totalDeliveries) {
      //       draftScore +=
      //         10 +
      //         getCount((state.deliveries[b + 1] as any).pinStatus) +
      //         getCount((state.deliveries[b + 2] as any).pinStatus);
      //       frameIndex++;
      //       ballIndex = 1;
      //     } else if (ballIndex === 2) {
      //     } else {
      //       ballIndex++;
      //     }

      //     // else (if (ballIndex == 2))
      //   }
      //   if (frameIndex === 11) {
      //     state.complete = true;
      //   }
      //   state.score = draftScore;
    },
    beginEditDelivery(state) {},
    setEditDelivery(state, action: PayloadAction<AvailableCollectors>) {
      // FIXME this is busted -> if editBall = 1, we need to adjust pinfall for ball 2...
      // not easy with current design
      // can't really have frames and deliveries decoupled....
      state.deliveries = state.deliveries.map(d =>
        d.id === state.editId ? action.payload : d,
      );
    },
    finishEditDelivery(state) {
      state.editBall = undefined;
      state.editFrame = undefined;
    },
    collect(state) {},
    newFrame(state) {},
    submit(state, action: PayloadAction<boolean | undefined>) {},
    commit(state) {},
    gameSyncApiRetry(state, action: PayloadAction<any>) {},
    gameSyncApiStart(state) {},
    gameSyncApiSuccess(state, action: PayloadAction<any>) {},
    completeSet(state) {
      return { ...initialState };
    },
  },
});

export const { actions: gameManagerActions, reducer } = slice;

export const useGameManagerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: gameManagerSaga });
  return { actions: slice.actions };
};

function addDelivery(state, delivery, after) {
  const index = state.deliveries.findIndex(delivery => delivery.id === after);
  state.deliveries.splice(index + 1, 0, delivery);
}

function removeDelivery(state, after) {
  const index = state.deliveries.findIndex(delivery => delivery.id === after);
  state.deliveries.splice(index + 1, 1);
}

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGameManagerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
