/**
 *
 * FrameNotes
 *
 */
import * as React from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Box, Button } from '@mui/material';
import { NoteEntry } from './NoteEntry';
interface Props {}

export function FrameNotes(props: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <Box padding={1}>
      <NoteEntry open={open} setOpen={setOpen} />
      <Button variant="outlined" fullWidth={true} onClick={() => setOpen(true)}>
        <EditNoteIcon fontSize="large" />
      </Button>
    </Box>
  );
}
