/**
 *
 * StatsSpares
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';

interface Props {
  frames: FrameSummary[][];
}

export function StatsSpares(props: Props) {
  const { frames } = props;
  let total = 0;
  let frameCount = 0;
  frames.forEach(game => {
    game.forEach(frame => {
      if (frame.firstBallCount !== 10) {
        if (frame.firstBallCount + (frame.secondBallCount || 0) === 10) {
          total++;
        }
        frameCount++;
      }
    });
  });

  return (
    <StatCard
      title="Spares"
      subtitle={`${total}/${frameCount}`}
      value={`${((100 * total) / frameCount).toFixed(2)}%`}
    />
  );
}
