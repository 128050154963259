/**
 *
 * StatsFirstBallAvg
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { StatCard } from '../StatCard';

interface Props {
  frames: FrameSummary[][];
}

export function StatsFirstBallAvg(props: Props) {
  const { frames } = props;

  let total = 0;
  let frameCount = 0;
  frames.forEach(game => {
    game.forEach(frame => {
      total += frame.firstBallCount;
      frameCount++;
    });
  });

  return (
    <StatCard
      title="First Ball Average"
      subtitle="All Balls"
      value={(total / frameCount).toFixed(2)}
    />
  );
}
