/**
 *
 * TargetList
 *
 */
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTargetListSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectTargetList } from './slice/selectors';
import { Delete, OpenWith } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { notificationsActions } from 'app/components/Notifications/slice';
interface Props {}

export function TargetList(props: Props) {
  const targetList = useTargetListSlice();
  const items = useSelector(selectTargetList).items;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(
      notificationsActions.toast({
        // component: <Typography variant="h3">Testing</Typography>,
        component:
          "You can save targets without registering for an account, but they will only be availabile on this device and could disappear if you alter your browser. Soon you'll be able to register for a free account!",
        options: {
          toastId: 'local-storage-warning-targets',
          type: 'warning',
          autoClose: false,
        },
      }),
    );
  });

  return (
    <>
      <Typography variant="h2">Saved Targets</Typography>
      <Box
        padding={1}
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      >
        <Button onClick={() => navigate(-1)}>
          <Typography variant="body1" padding={2} paddingBottom={0}>
            Back
          </Typography>
        </Button>
        <List dense={items.length > 10}>
          {items.map(item => (
            <ListItem
              alignItems="flex-start"
              key={item.id}
              secondaryAction={
                <ListItemIcon
                  onClick={() =>
                    dispatch(targetList.actions.deleteTargetLink(item))
                  }
                >
                  <Delete fontSize="small" />
                </ListItemIcon>
              }
            >
              <ListItemButton
                // component="a"
                href={'/target?' + item.link.replace(/http.*[\/\?]/, '')}
              >
                <ListItemText>{item.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}
