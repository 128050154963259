/**
 *
 * TargetExecution
 *
 */
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  SxProps,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTargetExecutionSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useGameManagerSlice } from '../GameManager/slice';
import { selectGameManager } from '../GameManager/slice/selectors';
import { selectTargetExecution } from './slice/selectors';

interface Props {}

export function TargetExecution(props: Props) {
  const dispatch = useDispatch();
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAccuracy: string,
  ) => {
    dispatch(actions.setAccuracy({ accuracy: parseInt(newAccuracy) }));
  };

  const actions = useTargetExecutionSlice().actions;
  useGameManagerSlice();

  const game = useSelector(selectGameManager);
  const execution = useSelector(selectTargetExecution);
  const accuracy = execution.accuracy;

  const fullRack = game.fullRack;

  const valueUnsetIndicatorSx: Partial<SxProps> = {
    borderTop: 1,
    borderTopColor: accuracy !== undefined ? 'green' : 'red',
  };
  return (
    <Box padding={1} sx={{ visibility: fullRack ? 'visible' : 'hidden' }}>
      <Typography fontSize=".75rem">Target Execution</Typography>
      <ToggleButtonGroup
        exclusive
        fullWidth={true}
        value={accuracy}
        onChange={handleChange}
        color="primary"
        sx={valueUnsetIndicatorSx}
      >
        <ToggleButton size="small" value={-3}>
          3+L
        </ToggleButton>
        <ToggleButton size="small" value={-2}>
          2L
        </ToggleButton>
        <ToggleButton size="small" value={-1}>
          1L
        </ToggleButton>
        <ToggleButton size="small" value={0}>
          0
        </ToggleButton>
        <ToggleButton size="small" value={1}>
          1R
        </ToggleButton>
        <ToggleButton size="small" value={2}>
          2R
        </ToggleButton>
        <ToggleButton size="small" value={3}>
          3+R
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
