/**
 *
 * GameManager
 *
 */
import * as React from 'react';
import { gameManagerActions, useGameManagerSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectGameManager } from './slice/selectors';
import { useSpeedControlSlice } from '../SpeedControl/slice';

export function GameManager() {
  useSpeedControlSlice();
  useGameManagerSlice();
  const dispatch = useDispatch();
  const game = useSelector(selectGameManager);

  React.useEffect(() => {
    if (!game.gameId) {
      dispatch(gameManagerActions.newGame({}));

      // move these to settings?
      // dispatch(
      //   deliveryActions.addCollector(pinFallControllerActions),
      // );
      // dispatch(
      //   deliveryActions.addCollector(ballDeckExitPointActions.report.type),
      // );
    }
  }, [dispatch, game.gameId]);

  return <div></div>;
}
