/**
 *
 * StatCard
 *
 */
import { Card, CardHeader, CardContent, Typography, Grid } from '@mui/material';
import * as React from 'react';

interface Props {
  title: String;
  subtitle?: String;
  value: String;
}

export function StatCard(props: Props) {
  const { title, subtitle, value } = props;
  return (
    <Grid container justifyContent="center">
      <Card sx={{ maxWidth: 200 }}>
        <CardHeader title={title} subheader={subtitle} />
        <CardContent>
          <Typography variant="h4">{value}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
