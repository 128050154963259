import { PinState } from 'app/components/PinFallController/slice/types';

export function isStrike(count) {
  return count === 10;
}

export function getCount(pinStatus: PinState[]) {
  return pinStatus.filter(pin => pin.active).filter(pin => !pin.up).length;
}

export function getInactiveCount(pinStatus: PinState[]) {
  return pinStatus.filter(pin => !pin.active).length;
}
