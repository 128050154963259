import {
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Lane from '../lane/Lane';
import { LANE_TOTAL_LENGTH, LANE_WIDTH } from '../lane/Lane/constants';
// import { targetLeftLaneActions as laneActions } from '../lane/Lane/slice';
import BasicModal from '../lane/Model';
import TransitionIcon from '@mui/icons-material/East';
import { useDispatch, useSelector } from 'react-redux';
import { selectTargetLeftLane as selectLane } from '../lane/Lane/slice/selectors';
import { AdvancedDialog } from './AdvancedDialog';
import { Engineering } from '@mui/icons-material';

interface Props {
  openSetLaydown: boolean;
  setOpenSetLaydown: React.Dispatch<React.SetStateAction<boolean>>;
  openSetTarget: boolean;
  setOpenSetTarget: React.Dispatch<React.SetStateAction<boolean>>;
  openSetExitpoint: boolean;
  setOpenSetExitpoint: React.Dispatch<React.SetStateAction<boolean>>;
  proposedNewTarget: any;
  setProposedNewMarker: any;
  selector: ReturnType<typeof selectLane>;
  actions: any;
}

export function TargetDialogs(props: Props) {
  const {
    openSetLaydown,
    setOpenSetLaydown,
    openSetTarget,
    setOpenSetTarget,
    openSetExitpoint,
    setOpenSetExitpoint,
    proposedNewTarget,
    setProposedNewMarker,
    selector: lane,
    actions,
  } = props;

  const [proposedNewBoard, setProposedNewBoard] = React.useState<string | null>(
    null,
  );

  const [openAdvanced, setOpenAdvanced] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <AdvancedDialog
        {...props}
        proposedNewBoard={proposedNewBoard}
        open={openAdvanced}
        setOpen={setOpenAdvanced}
        setCloseParent={setOpenSetTarget}
      />
      <BasicModal open={openSetLaydown} setOpen={setOpenSetLaydown}>
        <>
          <Typography variant="h2">Laydown</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new laydown board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={-(LANE_TOTAL_LENGTH - 30) * 5}
                initialScale={5}
                maxScale={20}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg
                    height={LANE_TOTAL_LENGTH}
                    width={LANE_WIDTH}
                    // width={window.innerWidth}
                    // width={window.innerWidth}
                    // width="100vh"
                    // viewBox={`0 500 ${window.innerWidth / lane.yScaleFactor} ${
                    //   window.innerHeight / 4
                    // }`}
                    // viewBox={`0 700 600 600`}
                    // preserveAspectRatio="xMinYMin meet"
                  >
                    {/* <g
                  transform={`scale(${
                    1 * heightScaleFactor
                  },${heightScaleFactor})`}
                > */}
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                    {/* </g> */}
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetLaydown(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5" paddingX={4}>
                      {lane.laydownBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(2)` }} />
                    <TextField
                      // value={proposedNewTarget.board.toFixed(1)}
                      style={{ padding: 30, margin: 0 }}
                      value={proposedNewBoard || proposedNewTarget.board}
                      onFocus={event => {
                        event.target.select();
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                        style: {
                          padding: 4,
                          fontSize: 28,
                          width: 100,
                        },
                      }}
                      // onSubmit={() => {
                      //   proposedNewBoard &&
                      //     setProposedNewMarker({
                      //       board: parseFloat(proposedNewBoard),
                      //       distance: proposedNewTarget.distance,
                      //     });
                      // }}
                      onChange={e => setProposedNewBoard(e.target.value)}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        const board =
                          parseFloat(proposedNewBoard as string) ||
                          proposedNewTarget.board;
                        dispatch(
                          actions.updateLaydownCalculateExit(board),
                          // dispatch(
                          //   actions.updateLaydownCalculateExit(proposedNewBoard),
                        );
                        setProposedNewBoard(null);
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetLaydown(false);
                      }}
                    >
                      Update
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
      <BasicModal open={openSetTarget} setOpen={setOpenSetTarget}>
        <>
          <Typography variant="h2">Target</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new target board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={
                  -(LANE_TOTAL_LENGTH - lane.targetDistance * 12 - 30) * 5
                }
                initialScale={5}
                maxScale={20}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetTarget(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5" paddingX={4}>
                      {lane.targetBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(2)` }} />
                    {/* <NumPad value={proposedNewTarget.board.toFixed(1)} /> */}

                    <TextField
                      // value={proposedNewTarget.board.toFixed(1)}
                      style={{ padding: 30, margin: 0 }}
                      value={proposedNewBoard || proposedNewTarget.board}
                      onFocus={event => {
                        event.target.select();
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                        style: {
                          padding: 4,
                          fontSize: 28,
                          width: 100,
                        },
                      }}
                      // onSubmit={() => {
                      //   proposedNewBoard &&
                      //     setProposedNewMarker({
                      //       board: parseFloat(proposedNewBoard),
                      //       distance: proposedNewTarget.distance,
                      //     });
                      // }}
                      onChange={e => setProposedNewBoard(e.target.value)}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Tooltip title="Set Target and calculate Laydown">
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          const board =
                            parseFloat(proposedNewBoard as string) ||
                            proposedNewTarget.board;
                          dispatch(
                            actions.updateTargetCalculateLaydown({
                              distance: proposedNewTarget.distance,
                              board,
                            }),
                          );
                          setProposedNewBoard(null);
                          setProposedNewMarker({ board: -1, distance: -1 });
                          setOpenSetTarget(false);
                        }}
                      >
                        Update
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title="Advanced"
                      onClick={() => setOpenAdvanced(true)}
                    >
                      <Engineering />
                    </Tooltip>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
      <BasicModal open={openSetExitpoint} setOpen={setOpenSetExitpoint}>
        <>
          <Typography variant="h2">Pattern Exit</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new exit board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={
                  -(LANE_TOTAL_LENGTH - lane.exitDistance * 12 - 30) * 5
                }
                initialScale={5}
                maxScale={20}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetExitpoint(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h3" paddingX={4}>
                      {lane.exitBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(3)` }} />
                    <Typography variant="h3" paddingX={4}>
                      {proposedNewTarget.board.toFixed(1)}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          actions.updateExitCalculateLaydown({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetExitpoint(false);
                      }}
                    >
                      Update Laydown
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          actions.updateExitCalculateTarget({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetExitpoint(false);
                      }}
                    >
                      Update Target
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
    </>
  );
}
