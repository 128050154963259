/**
 *
 * DeliveryController
 *
 */
import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGameManagerSlice } from '../GameManager/slice';
import { selectGameManager } from '../GameManager/slice/selectors';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';
import { useBowlingSetSlice } from '../BowlingSet/slice';
import { selectBowlingSet } from '../BowlingSet/slice/selectors';

interface Props {}

export function DeliveryController(props: Props) {
  const { actions: gameManagerActions } = useGameManagerSlice();
  const { actions: bowlingSetActions } = useBowlingSetSlice();

  const game = useSelector(selectGameManager);
  const set = useSelector(selectBowlingSet);
  const dispatch = useDispatch();
  const { setAppActions } = React.useContext(AppActionsContext);

  React.useEffect(() => {
    const NextGame = () => (
      <Button
        fullWidth={true}
        variant="contained"
        onClick={() => {
          dispatch(gameManagerActions.newGame({ setId: game.setId as string }));
          dispatch(gameManagerActions.commit());
        }}
      >
        Next Game
      </Button>
    );

    const CompleteSet = () => (
      <Button
        fullWidth={true}
        variant="contained"
        onClick={() => dispatch(bowlingSetActions.complete())}
        // onClick={() => dispatch(gameManagerActions.completeSet())}
      >
        Complete Set
      </Button>
    );

    const Record = () => (
      <Button
        fullWidth={true}
        variant="contained"
        onClick={() => dispatch(gameManagerActions.submit())}
      >
        record
      </Button>
    );

    const Change = () => (
      <Button
        fullWidth={true}
        variant="contained"
        onClick={() => dispatch(gameManagerActions.submit(game.editId as any))}
      >
        change
      </Button>
    );

    const Cancel = () => (
      <Button
        fullWidth={true}
        variant="outlined"
        onClick={() => dispatch(gameManagerActions.finishEditDelivery())}
      >
        Cancel
      </Button>
    );
    setAppActions([
      <Box key="actions" flex={1} sx={{ px: 2 }}>
        {!set.inProgress && <></>}
        {game.complete && (
          <Stack direction="row" spacing={1}>
            <NextGame />
            <CompleteSet />
          </Stack>
        )}
        {!game.complete && !game.editBall && <Record />}
        {!game.complete && game.editBall && (
          <Stack direction="row" spacing={1}>
            <Change />
            <Cancel />
          </Stack>
        )}
      </Box>,
    ]);
  }, [
    bowlingSetActions,
    dispatch,
    game.complete,
    game.editBall,
    game.editId,
    game.setId,
    gameManagerActions,
    set.inProgress,
    setAppActions,
  ]);

  return <></>;
}
