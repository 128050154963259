/**
 *
 * TargetLeftLane
 *
 */
import * as React from 'react';
import { useTargetLeftSlice, useTargetRightSlice } from '../lane/Lane/slice';
import {
  selectTargetLeftLane,
  selectTargetRightLane,
} from '../lane/Lane/slice/selectors';
import { useSelector } from 'react-redux';
import { Target } from './Target';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { TargetCard } from './TargetCard';
import { SimpleTargetMoves } from './SimpleTargetMoves';
import { useGameManagerSlice } from '../GameManager/slice';

interface Props {}

export function LaneTargets(props: Props) {
  useGameManagerSlice();
  const leftActions = useTargetLeftSlice();
  const leftLane = useSelector(selectTargetLeftLane);
  const rightActions = useTargetRightSlice();
  const rightLane = useSelector(selectTargetRightLane);
  const [openLeft, setOpenLeft] = React.useState<0 | 1 | 2>(0);
  const [openRight, setOpenRight] = React.useState<0 | 1 | 2>(0);
  return (
    <>
      <Target
        open={openLeft}
        setOpen={setOpenLeft}
        actions={leftActions.actions}
        selector={leftLane}
      />
      <Target
        open={openRight}
        setOpen={setOpenRight}
        actions={rightActions.actions}
        selector={rightLane}
      />

      <SimpleTargetMoves
        open={openLeft}
        setOpen={setOpenLeft}
        selector={leftLane}
        otherSelector={rightLane}
        actions={leftActions.actions}
      />
      <SimpleTargetMoves
        open={openRight}
        setOpen={setOpenRight}
        selector={rightLane}
        otherSelector={leftLane}
        actions={rightActions.actions}
      />

      <Stack direction={'row'}>
        <Button onClick={() => setOpenLeft(1)}>
          <TargetCard lane="Left" {...leftLane}></TargetCard>
        </Button>
        <Button onClick={() => setOpenRight(1)}>
          <TargetCard lane="Right" {...rightLane}></TargetCard>
        </Button>
      </Stack>
    </>
  );
}
