import { Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const options: Partial<GridColDef> = {
  headerAlign: 'left',
  align: 'left',
  editable: false,
  minWidth: 130,
  flex: 1,
};

// "type": "appTesting",
// "event": "testing 2",
// "date": "2024-02-17",
// "startTime": "2024-02-17T20:55:53.663Z",
// "id": "17aade4d-e86a-4e5b-a180-5abbb7521036",
// "userId": "1eefc60d-c645-4ef2-888c-acb3a5223f43",
// "userFirstName": "Larry",
// "userLastName": "Sanders",
// "created": "2024-02-17T20:55:54.514Z",
// "updated": "2024-02-17T20:55:54.514Z"
export const columns: GridColDef[] = [
  {
    ...options,
    field: 'type',
    flex: 0,
    minWidth: 20,
    width: 40,
    type: 'string',
    valueFormatter: v => v.value.substring(0, 1).toUpperCase(),
    renderCell: (params: GridRenderCellParams<any, String>) => (
      <Tooltip title={params.value}>{params.formattedValue as any}</Tooltip>
    ),
    headerName: 'Type',
  },
  {
    ...options,
    field: 'startTime',
    type: 'string',
    valueFormatter: v => dayjs(v.value).fromNow(),
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <Tooltip title={dayjs(params.value).format('LLLL')}>
        {params.formattedValue as any}
      </Tooltip>
    ),
    headerName: 'Time',
  },
  {
    ...options,
    field: 'userFirstName',
    type: 'string',
    headerName: 'First',
    // flex: 0,
  },
  {
    ...options,
    field: 'userLastName',
    type: 'string',
    headerName: 'Last',
    flex: 0,
  },
  {
    ...options,
    field: 'event',
    type: 'string',
    headerName: 'Event',
  },
  {
    ...options,
    field: 'created',
    type: 'string',
    valueFormatter: v => dayjs(v.value).fromNow(),
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <Tooltip title={dayjs(params.value).format('LLLL')}>
        {params.formattedValue as any}
      </Tooltip>
    ),
    headerName: 'Created',
  },
  {
    ...options,
    field: 'updated',
    type: 'string',
    valueFormatter: v => dayjs(v.value).fromNow(),
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <Tooltip title={dayjs(params.value).format('LLLL')}>
        {params.formattedValue as any}
      </Tooltip>
    ),
    headerName: 'Updated',
  },

  // {
  //   ...options,
  //   field: 'type',
  //   type: 'string',
  //   headerName: 'Type',
  // },
  // {
  //   ...options,
  //   field: 'event',
  //   type: 'string',
  //   headerName: 'Event',
  //   show: true,
  // },
];
