import {
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  TextField,
  Divider,
} from '@mui/material';
import * as React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Lane from '../lane/Lane';
import { LANE_TOTAL_LENGTH, LANE_WIDTH } from '../lane/Lane/constants';
// import { targetLeftLaneActions as laneActions } from '../lane/Lane/slice';
import BasicModal from '../lane/Model';
import TransitionIcon from '@mui/icons-material/East';
import { useDispatch, useSelector } from 'react-redux';
import { selectTargetLeftLane as selectLane } from '../lane/Lane/slice/selectors';
import { laneActions } from '../lane/Lane/slice';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCloseParent: React.Dispatch<React.SetStateAction<boolean>>;
  proposedNewBoard: any;
  proposedNewTarget: any;
  setProposedNewMarker: any;
  selector: ReturnType<typeof selectLane>;
  actions: typeof laneActions;
}

export function AdvancedDialog(props: Props) {
  const {
    open,
    setOpen,
    setCloseParent,
    proposedNewBoard,
    proposedNewTarget,
    selector: lane,
    actions,
  } = props;

  const dispatch = useDispatch();

  return (
    <>
      <BasicModal open={open} setOpen={setOpen}>
        <>
          <Typography variant="h2">Setting Target</Typography>
          <Typography variant="body2">
            Chose which point of the three point system to hold constant and
            which to calculate
          </Typography>
          <Divider variant="fullWidth" style={{ margin: 10 }} />
          <Stack spacing={2}>
            <Button
              fullWidth
              sx={{ textTransform: 'none' }}
              size="small"
              variant="contained"
              onClick={() => {
                const board =
                  parseFloat(proposedNewBoard as string) ||
                  proposedNewTarget.board;
                dispatch(
                  actions.updateTargetCalculateLaydown({
                    distance: proposedNewTarget.distance,
                    board,
                  }),
                );
                setOpen(false);
                setCloseParent(false);
              }}
            >
              Update Laydown keep Exit Point
            </Button>
            <Button
              fullWidth
              sx={{ textTransform: 'none' }}
              size="small"
              variant="contained"
              onClick={() => {
                const board =
                  parseFloat(proposedNewBoard as string) ||
                  proposedNewTarget.board;
                dispatch(
                  actions.updateTargetCalculateExit({
                    distance: proposedNewTarget.distance,
                    board,
                  }),
                );
                setOpen(false);
                setCloseParent(false);
              }}
            >
              Update Exit Point keep Laydown
            </Button>
          </Stack>
        </>
      </BasicModal>
    </>
  );
}
