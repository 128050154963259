import {
  DataGrid,
  GridRowModesModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import React from 'react';
import { EditToolbar } from './AddLoadToolbar';
import { columns } from './grid-column-def';
import { LoadsData } from 'app/components/lane/Pattern/types';

interface Props {
  loads: LoadsData[];
  setLoads: React.Dispatch<React.SetStateAction<LoadsData[]>>;
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  editable?: boolean;
}

export function PatternLoadsDataGrid(props: Props) {
  const { rowSelectionModel, setRowSelectionModel } = props;
  React.useState<GridRowSelectionModel>([]);
  const [rowModesModelForward, setRowModesModelForward] =
    React.useState<GridRowModesModel>({});

  return (
    <DataGrid
      density="compact"
      getRowId={row => row.num}
      rows={props.loads}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      checkboxSelection
      editMode="row"
      rowSelectionModel={rowSelectionModel}
      processRowUpdate={(updatedRow, originalRow) => {
        props.setLoads(
          props.loads.map(row =>
            row.num === updatedRow.num ? updatedRow : row,
          ),
        );
        return updatedRow;
      }}
      onRowSelectionModelChange={
        setRowSelectionModel
          ? (selectionModel, details) => {
              setRowSelectionModel(selectionModel);
            }
          : undefined
      }
      onRowModesModelChange={rowModesModel => {
        setRowModesModelForward(rowModesModel);
      }}
      slots={{
        toolbar: EditToolbar,
      }}
      rowModesModel={rowModesModelForward}
      slotProps={{
        toolbar: {
          setRows: props.setLoads,
          setRowModesModel: setRowModesModelForward,
          setRowSelectModel: setRowSelectionModel,
          loads: props.loads,
        },
      }}
    />
  );
}
