import { ReactNode, useState } from 'react';
import {
  Alert,
  AlertColor,
  Badge,
  Box,
  IconButton,
  Popper,
  Fade,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from '@mui/material';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';

import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import { toast, TypeOptions } from 'react-toastify';
import React from 'react';

const types = ['success', 'info', 'warning', 'error'];

export default function Notifications() {
  const { notifications, clear, markAllAsRead, markAsRead, unreadCount } =
    useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const toggleFilter = (e: React.ChangeEvent) => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  return (
    <Box sx={{ margin: '0px' }}>
      <IconButton size="medium" onClick={toggleNotificationCenter}>
        <Badge badgeContent={unreadCount} color="primary" sx={{ padding: 0 }}>
          <NotificationsIcon color="action" sx={{ margin: 0, padding: 0 }} />
        </Badge>
      </IconButton>
      {/* <button onClick={addNotification}>Add notification</button> */}

      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        transition
        sx={{ zIndex: 5000 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box width="82vw">
              <Box
                sx={{
                  background: '#666',
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" color="#fff">
                  Notification center
                </Typography>
                <FormGroup sx={{ color: '#fff' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        onChange={toggleFilter}
                        checked={showUnreadOnly}
                      />
                    }
                    label="Show unread only"
                  />
                </FormGroup>
              </Box>
              <Stack
                sx={{
                  height: '400px',
                  width: '80vw',
                  padding: '12px',
                  background: '#f1f1f1',
                  borderRadius: '8px',
                  overflowY: 'auto',
                }}
                spacing={2}
              >
                {(!notifications.length ||
                  (unreadCount === 0 && showUnreadOnly)) && (
                  <h4>
                    Your queue is empty! you are all set{' '}
                    <span role="img" aria-label="dunno what to put">
                      🎉
                    </span>
                  </h4>
                )}
                {(showUnreadOnly
                  ? notifications.filter(v => !v.read)
                  : notifications
                ).map((notification, i) => {
                  return (
                    <Alert
                      key={`note-${i}`}
                      severity={(notification.type as AlertColor) || 'info'}
                      action={
                        notification.read ? (
                          <CheckIcon />
                        ) : (
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => markAsRead(notification.id)}
                          >
                            <MarkChatReadIcon />
                          </IconButton>
                        )
                      }
                    >
                      {notification.content as ReactNode}
                    </Alert>
                  );
                })}
              </Stack>
              <Box
                sx={{
                  background: '#666',
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button variant="contained" onClick={clear}>
                  Clear All
                </Button>

                <Button variant="contained" onClick={markAllAsRead}>
                  Mark all as read
                </Button>
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
