/**
 *
 * SetList
 *
 */
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import * as React from 'react';
import { columns } from './grid-column-def';

type rows = {
  type: string;
  event: string;
  id: string;
};
interface Props {
  rows: rows[];
}

export function SetList(props: Props) {
  const defaultHidden =
    window.innerWidth < 400
      ? {
          userLastName: false,
          created: false,
          startTime: false,
          type: false,
        }
      : {};
  const { rows } = props;
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [visabilityModel, setVisabilityModel] =
    React.useState<GridColumnVisibilityModel>(defaultHidden as any);
  return (
    <>
      {rowSelectionModel.length > 0 && <p>{rowSelectionModel[0]}</p>}
      <DataGrid
        density="compact"
        getRowId={row => row.id}
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: visabilityModel,
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        columnVisibilityModel={visabilityModel}
        onColumnVisibilityModelChange={
          setVisabilityModel
            ? (visModel, details) => {
                setVisabilityModel(visModel);
              }
            : undefined
        }
        // checkboxSelection
        // editMode="row"
        // rowSelectionModel={rowSelectionModel}
        // processRowUpdate={(updatedRow, originalRow) => {
        //   props.setLoads(
        //     props.loads.map(row =>
        //       row.num === updatedRow.num ? updatedRow : row,
        //     ),
        //   );
        //   return updatedRow;
        // }}
        onRowSelectionModelChange={
          setRowSelectionModel
            ? (selectionModel, details) => {
                setRowSelectionModel(selectionModel);
              }
            : undefined
        }
        // onRowModesModelChange={rowModesModel => {
        //   setRowModesModelForward(rowModesModel);
        // }}
        // slots={{
        //   toolbar: EditToolbar,
        // }}
        // rowModesModel={rowModesModelForward}
        // slotProps={{
        //   toolbar: {
        //     setRows: props.setLoads,
        //     setRowModesModel: setRowModesModelForward,
        //     setRowSelectModel: setRowSelectionModel,
        //     loads: props.loads,
        //   },
        // }}
      />
    </>
  );
}
