import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { LoadsData } from 'app/components/lane/Pattern/types';
import React from 'react';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  setRowSelectModel: Function;
  loads: LoadsData[];
}

export const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel, setRowSelectModel, loads } = props;

  const handleClick = () => {
    const id = loads.length + 1;
    setRows(oldRows => [
      ...oldRows,
      {
        num: id,
        buf: '',
        load: '',
        mics: '',
        speed: '',
        start: '',
        stop: '',
        startDistance: '',
        stopDistance: '',
        tank: '',
        toil: '',
      },
    ]);
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: {
        mode: GridRowModes.Edit,
        fieldToFocus: 'start',
      },
    }));

    setRowSelectModel([...loads.map(i => i.num), id]);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
};
