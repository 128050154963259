import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { Entry, PocketEntryState } from './types';
import { gameManagerActions } from 'app/components/GameManager/slice';
import { selectBallDeckExitPointReport } from 'app/components/BallDeckExitPoint/slice/selectors';
import { selectPocketEntry, selectPocketEntryReport } from './selectors';

export const initialState: PocketEntryState = {
  entry: undefined,
};

const slice = createSlice({
  name: 'pocketEntry',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(
        gameManagerActions.commit,
        (state, action: PayloadAction<any>) => {
          return initialState;
          // return state;
        },
      )
      .addCase(
        gameManagerActions.beginEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.save = state.entry;
        },
      )
      .addCase(
        gameManagerActions.setEditDelivery,
        (
          state,
          action: PayloadAction<{
            pocketEntry?: ReturnType<typeof selectPocketEntryReport>;
          }>,
        ) => {
          state.entry = action.payload.pocketEntry || undefined;
        },
      )
      .addCase(
        gameManagerActions.finishEditDelivery,
        (state, action: PayloadAction<any>) => {
          state.entry = state.save;
        },
      ),

  reducers: {
    setPocketEntry(state, action: PayloadAction<{ entry: Entry }>) {
      state.entry = action.payload.entry;
    },
  },
});

export const { actions: pocketEntryActions } = slice;

export const usePocketEntrySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePocketEntrySlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
