import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { notificationsSaga } from './saga';
import { NotificationsState } from './types';
import { ToastOptions } from 'react-toastify';

export const initialState: NotificationsState = {};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toast(
      state,
      action: PayloadAction<{ component: any; options?: ToastOptions }>,
    ) {},
  },
});

export const { actions: notificationsActions } = slice;

export const useNotificationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useNotificationsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
