/**
 *
 * PatternEditor
 *
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import { GridRowModesModel, GridRowSelectionModel } from '@mui/x-data-grid';
import { columns } from './grid-column-def';
import { useSelector } from 'react-redux';
import { selectLane } from 'app/components/lane/Lane/slice/selectors';
import { useLaneSlice } from 'app/components/lane/Lane/slice';
import { LoadsData, PatternData } from 'app/components/lane/Pattern/types';
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { parseRawLoads } from 'utils/pattern';
import { Pattern } from 'app/components/lane/Pattern';
import {
  BOARD_WIDTH,
  LANE_WIDTH,
  PIN_DECK_LENGTH,
} from 'app/components/lane/Lane/constants';
import LaneArrows from 'app/components/lane/LaneArrows';
import RangeFinders from 'app/components/lane/RangeFinders';
import { PatternLoadsDataGrid } from './PatternLoadsDataGrid';
import { BrowserExperienceNotice } from 'app/components/BrowserExperienceNotice';

interface Props {
  pattern?: PatternData;
}

export function PatternEditor(props: Props) {
  useLaneSlice();
  const [rowSelectionModelForward, setRowSelectionModelForward] =
    React.useState<GridRowSelectionModel>([]);
  const [rowSelectionModelReverse, setRowSelectionModelReverse] =
    React.useState<GridRowSelectionModel>([]);
  const [forwardLoads, setForwardloads] = React.useState<LoadsData[]>([]);
  const [reverseLoads, setReverseLoads] = React.useState<LoadsData[]>([]);
  const [patternName, setPatternName] = React.useState<string>('');
  const [draftPattern, setDraftPattern] = React.useState<PatternData>();
  const [displayOptions, setDisplayOptions] = React.useState([
    'outlineForward',
    'outlineReverse',
  ]);

  const handleDisplayOptions = (
    event: React.MouseEvent<HTMLElement>,
    newOptions: string[],
  ) => {
    setDisplayOptions(newOptions);
  };
  const showPattern = React.useCallback(() => {
    setDraftPattern({
      name: patternName,
      loads: {
        forward: forwardLoads.filter(item => {
          const include = rowSelectionModelForward.includes(item.num);
          return include;
        }),
        reverse: reverseLoads.filter(item => {
          const include = rowSelectionModelReverse.includes(item.num);
          return include;
        }),
      },
    });
  }, [
    forwardLoads,
    patternName,
    reverseLoads,
    rowSelectionModelForward,
    rowSelectionModelReverse,
  ]);

  const height = 63 * 12;
  React.useEffect(() => {
    if (props.pattern) {
      setPatternName(props.pattern.name);
      setForwardloads(props.pattern.loads.forward);
      setRowSelectionModelForward(props.pattern.loads.forward.map(i => i.num));
      setReverseLoads(props.pattern.loads.reverse);
      setRowSelectionModelReverse(props.pattern.loads.reverse.map(i => i.num));
      showPattern();
    }
  }, [props.pattern, showPattern]);

  useLaneSlice();
  const lane = useSelector(selectLane);

  const options = lane.patterns.map(option => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const onPatternChange = (event, newValue) => {
    console.log(newValue);
    setPatternName(newValue.name);
    setForwardloads(newValue.loads.forward);
    setRowSelectionModelForward(newValue.loads.forward.map(i => i.num));
    setReverseLoads(newValue.loads.reverse);
    setRowSelectionModelReverse(newValue.loads.reverse.map(i => i.num));
    showPattern();
  };

  return (
    <>
      <BrowserExperienceNotice pageId="pagger-editor" />
      <Stack direction="row">
        <Autocomplete
          sx={{ p: 4 }}
          fullWidth={true}
          id="combo-box-patterns"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
          )}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <TextField {...params} label="Pattern" />
            </div>
          )}
          onChange={onPatternChange}
        />
        <ToggleButtonGroup
          value={displayOptions}
          onChange={handleDisplayOptions}
          size="small"
          color="primary"
        >
          <ToggleButton value="outlineForward">
            Outline Forward Loads
          </ToggleButton>
          <ToggleButton value="outlineReverse">
            Outline Reverse Loads
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Grid container spacing={0} margin={2}>
        <Grid item xs={9} overflow="scroll">
          <form
            onSubmit={e => {
              e.preventDefault();
              showPattern();
            }}
          >
            <Grid item padding={1}>
              <Button variant="contained" type="submit">
                Generate pattern data
              </Button>
            </Grid>
            <Grid item>
              <Stack pt={3}>
                <TextField
                  value={patternName}
                  label="Pattern Name"
                  placeholder="enter the pattern name"
                  onChange={e => {
                    console.log(e.currentTarget.value);
                    setPatternName(e.currentTarget.value);
                  }}
                ></TextField>
              </Stack>
            </Grid>
            <Grid item margin={2}>
              <Stack spacing={0.5}>
                <Box>
                  <Typography variant="h5">Forward Loads</Typography>
                  <Button
                    onClick={() =>
                      navigator.clipboard.read().then(items =>
                        items[0].getType('text/plain').then(textType =>
                          textType.text().then(str => {
                            const loads = parseRawLoads(str);
                            setForwardloads(loads);
                            setRowSelectionModelForward(loads.map(i => i.num));
                          }),
                        ),
                      )
                    }
                  >
                    <Typography>Load from clipboard</Typography>
                  </Button>
                </Box>
                <Box
                  margin={2}
                  padding={1}
                  // sx={{ height: '30%', width: '100%' }}
                >
                  <PatternLoadsDataGrid
                    loads={forwardLoads}
                    setLoads={setForwardloads}
                    rowSelectionModel={rowSelectionModelForward}
                    setRowSelectionModel={setRowSelectionModelForward}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item margin={2}>
              <Stack spacing={0.5}>
                <Box>
                  <Typography variant="h5">Reverse Loads</Typography>
                  <Button
                    onClick={() =>
                      navigator.clipboard.read().then(items =>
                        items[0].getType('text/plain').then(textType =>
                          textType.text().then(str => {
                            const loads = parseRawLoads(str);
                            setReverseLoads(loads);
                            setRowSelectionModelReverse(loads.map(i => i.num));
                          }),
                        ),
                      )
                    }
                  >
                    <Typography>Load from clipboard</Typography>
                  </Button>
                </Box>
                <Box
                  margin={2}
                  padding={1}
                  // sx={{ height: '30%', width: '100%' }}
                >
                  <PatternLoadsDataGrid
                    loads={reverseLoads}
                    setLoads={setReverseLoads}
                    rowSelectionModel={rowSelectionModelReverse}
                    setRowSelectionModel={setRowSelectionModelReverse}
                  />
                </Box>
              </Stack>
            </Grid>
          </form>
        </Grid>
        <Box
          height="100%"
          component={Grid}
          overflow={'visible'}
          item
          xs={3}
          padding={0}
          margin={0}
        >
          {draftPattern && (
            <svg
              height="100vh"
              width="100%"
              viewBox={`0 ${(PIN_DECK_LENGTH + 60 * 12) / 6} ${LANE_WIDTH} ${
                PIN_DECK_LENGTH + 60 * 12
              }`}
              preserveAspectRatio="xMidYMid meet"
              transform="scale(3,1)"
              pointerEvents="none"
            >
              <Pattern
                pattern={draftPattern}
                outlineForwardLoads={displayOptions.includes('outlineForward')}
                outlineReverseLoads={displayOptions.includes('outlineReverse')}
                showForwardPattern
                showReversePattern
              />
              <g transform={`translate(0,${60 * 12 + 34 - 16 * 12})`}>
                <LaneArrows height={height} boardWidth={BOARD_WIDTH} />
              </g>

              <g transform={`translate(0,${60 * 12 + 34 - 43 * 12})`}>
                <RangeFinders height={height} boardWidth={BOARD_WIDTH} />
              </g>
            </svg>
          )}
        </Box>
        <Grid item>
          <TextField value={JSON.stringify(draftPattern)} size="medium" />
        </Grid>
      </Grid>
    </>
  );
}
