/**
 *
 * Pricing
 *
 */
import PricingHero from 'app/components/PricingHero';
import * as React from 'react';

interface Props {}

export function PricingPage(props: Props) {
  return <PricingHero />;
}
