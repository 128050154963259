// FIXME - rename to Range
/**
 *
 * LaneArrow
 *
 */

import React from 'react';

//import styles from './styles.css';

interface Props {
  x: number;
  y: number;
  height: number;
  width: number;
}

export const RangeFinder = (props: Props) => {
  // eslint-disable-line react/prefer-stateless-function
  const transLoc = `translate(${props.x - props.width / 2}, ${props.y})`;
  return (
    <svg>
      <g transform={transLoc}>
        <rect
          width={props.width}
          height={props.height}
          fill="black"
          stroke="black"
          strokeWidth={0.25}
        />
      </g>
    </svg>
  );
};

export default RangeFinder;
