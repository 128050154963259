import { BorderBottom } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  SliderTrack,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { laneActions } from '../lane/Lane/slice';
import { selectLane } from '../lane/Lane/slice/selectors';
import PatternSelectorDrawer from '../lane/Pattern/PatternSelectorDrawer';
import PatternDropDown from '../lane/Pattern/PatternDropDown';

interface Props {
  open: 0 | 1 | 2;
  setOpen: Function;
  selector: ReturnType<typeof selectLane>;
  actions: typeof laneActions;
  otherSelector: ReturnType<typeof selectLane>;
}

export function SimpleTargetMoves(props: Props) {
  const { open, setOpen, selector: lane, actions, otherSelector } = props;
  const dispatch = useDispatch();
  const makeMove = (laydownChange, targetChange) => {
    dispatch(actions.makeMove({ laydownChange, targetChange }));
    setOpen(0);
  };
  return (
    <Drawer open={open === 1 ? true : false} onClose={() => setOpen(0)}>
      <Box
        padding={3}
        margin={3}
        // width="90vw"
        // height="100%"
        // overflow={'hidden'}
        // item
        // xs={12}
        // padding={0}
        // margin={0}
        // alignContent={'center'}
        // alignItems={'center'}
        // justifyContent={'center'}
      >
        <Stack spacing={2}>
          <Button
            onClick={() => {
              dispatch(actions.setLine((otherSelector as any).encodedLine));
              setOpen(0);
            }}
          >
            Set like other lane
          </Button>
          <Typography variant="h5" textAlign={'center'}>
            Simple Moves
          </Typography>
          <Stack direction={'row'} justifyContent={'center'} spacing={1}>
            <Stack>
              <Typography textAlign={'center'}>Left</Typography>
              <Divider />
              <Button onClick={() => makeMove(0.5, 0)}>½ & 0</Button>
              <Button onClick={() => makeMove(1, 0)}>1 & 0</Button>
              <Button onClick={() => makeMove(1, 0.5)}>1 & ½</Button>
              <Button onClick={() => makeMove(1, 1)}>1 & 1</Button>
              <Button onClick={() => makeMove(2, 1)}>2 & 1</Button>
              <Button onClick={() => makeMove(2, 2)}>2 & 2</Button>
              <Button onClick={() => makeMove(3, 2)}>3 & 2</Button>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack>
              <Typography textAlign={'center'}>Right</Typography>
              <Divider />
              <Button onClick={() => makeMove(-0.5, 0)}>½ & 0</Button>
              <Button onClick={() => makeMove(-1, 0)}>1 & 0</Button>
              <Button onClick={() => makeMove(-1, -0.5)}>1 & ½</Button>
              <Button onClick={() => makeMove(-1, -1)}>1 & 1</Button>
              <Button onClick={() => makeMove(-2, -1)}>2 & 1</Button>
              <Button onClick={() => makeMove(-2, -2)}>2 & 2</Button>
              <Button onClick={() => makeMove(-3, -2)}>3 & 2</Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack margin={3}>
          {/* <Stack direction="row" spacing={3} margin={2}>
            <TextField
              onChange={}
              value={lane.targetBoard.toFixed(1)}
              label="Target"
              variant="filled"
            />
            <TextField
              value={lane.targetDistance.toFixed(1)}
              label="Distance"
              variant="filled"
            />
          </Stack> */}
          <Divider sx={{ borderBottomWidth: 4 }} />
          <Button onClick={() => setOpen(2)}>
            <Typography textAlign={'center'}>Visualize</Typography>
          </Button>
        </Stack>
        <Box width="60vw">
          <PatternDropDown actions={actions} lane={lane} />
        </Box>
      </Box>
    </Drawer>
  );
}
