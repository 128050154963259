import { notificationsActions } from 'app/components/Notifications/slice';
import { take, put, takeLeading } from 'redux-saga/effects';
import { v4 } from 'uuid';
// import { networkManagerActions as actions } from '.';

function* networkWaitForRestore() {
  const faultId = v4();
  const faultTime = new Date();
  yield put(
    notificationsActions.toast({
      component: `Couldn't reach the server, but don't worry, we'll retry and let you know when we've recovered`,
      options: {
        type: 'error',
        toastId: faultId,
      },
    }),
  );
  yield take(action => /.*ApiSuccess.*/.test(action.type));
  yield put(
    notificationsActions.toast({
      component: `Service restored - unavailable for ${(
        (new Date().valueOf() - faultTime.valueOf()) /
        1000
      ).toFixed(0)} seconds`,
      options: {
        type: 'success',
      },
    }),
  );
}

export function* networkManagerSaga() {
  yield takeLeading(action => {
    console.log(action);
    return /.*ApiRetry.*/.test(action.type);
  }, networkWaitForRestore);
  console.log('im here');
}
