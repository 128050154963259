// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { gameManagerActions as actions } from '.';
import { selectGameManager } from './selectors';
import { AvailableCollectors, COLLECTOR_MAP } from './types';
import { putGameInfo } from 'api/game';
import { v4 } from 'uuid';
import { selectBowlingSet } from 'app/components/BowlingSet/slice/selectors';
import { notificationsActions } from 'app/components/Notifications/slice';

// function* doSomething() {}

export function* gameManagerSaga() {
  yield takeLatest([actions.newGame, actions.restoreGame], gameWatcher);
  yield takeLatest([actions.newGame, actions.restoreGame], editWatcher);
  yield takeLatest(actions.commit, apiSynchronizer);
  // yield takeLatest([actions.finishEditDelivery], cacheRestore)
}

export function* editWatcher() {
  let game: ReturnType<typeof selectGameManager>;
  do {
    yield take(actions.setEdit);
    yield put(actions.beginEditDelivery());
    game = yield select(selectGameManager);

    const frameToEdit = game.frames[(game.editFrame as number) - 1];
    if (frameToEdit && frameToEdit.deliveries) {
      const delivery = frameToEdit.deliveries[(game.editBall || 1) - 1];
      yield put(actions.setEditDelivery(delivery));
    }
  } while (!game.complete);
}

export function* getCollectors(game) {
  let report = {};
  for (let i = 0; i < game.enabledCollectors.length; i++) {
    const collector = COLLECTOR_MAP[game.enabledCollectors[i]];
    const collectorReport = yield select(collector);
    report[game.enabledCollectors[i]] = collectorReport;
  }
  return report;
}
export function* gameWatcher() {
  let game: ReturnType<typeof selectGameManager>;
  do {
    const editId = yield take(actions.submit);

    // const pinStatus = yield select(selectPinFallReport);
    // const reportValues: AvailableCollectors = { pinStatus };

    game = yield select(selectGameManager);
    const report = yield call(getCollectors, game);

    if (editId.payload) {
      yield put(actions.record(report as AvailableCollectors));
      yield put(actions.finishEditDelivery());
    } else {
      yield put(actions.record(report as AvailableCollectors));
      yield put(actions.commit());
      game = yield select(selectGameManager);
      if (game.currentBall === 1) {
        yield put(actions.newFrame());
      }
    }
  } while (!game.complete);
  console.log('game watcher done....');
}

export function* updateApi() {
  while (true) {
    try {
      const game: ReturnType<typeof selectGameManager> = yield select(
        selectGameManager,
      );
      const set = yield select(selectBowlingSet);
      const apiResponse = yield call(putGameInfo, {
        setId: set.id,
        gameId: game.gameId,
        gameInfo: game.frames,
      });
      return apiResponse;
    } catch (error) {
      yield put(actions.gameSyncApiRetry((error as string).toString()));
      yield delay(20000);
    }
  }
}

export function* updateResource() {
  const apiResponse = yield call(updateApi);
  yield put(actions.gameSyncApiSuccess(apiResponse));
}
export function* apiSynchronizer() {
  yield put(actions.gameSyncApiStart());
  yield call(updateResource);
}
