/**
 *
 * BowlingSet
 *
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useBowlingSetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectBowlingSet } from './slice/selectors';
import { useTargetLeftSlice, useTargetRightSlice } from '../lane/Lane/slice';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';

interface Props {
  children: React.ReactNode;
}
export function BowlingSet(props: Props) {
  const { children } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const [event, setEvent] = React.useState('');
  const [lane, setLane] = React.useState<number | ''>('');
  const [laydown, setLaydown] = React.useState<string | ''>('');
  const [target, setTarget] = React.useState<string | ''>('');
  const [eventType, setEventType] = React.useState('appTesting');

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleEventTypeChange = (event: SelectChangeEvent) => {
    setEventType(event.target.value);
  };

  const { setAppActions } = React.useContext(AppActionsContext);
  const { actions } = useBowlingSetSlice();
  const bowlingSet = useSelector(selectBowlingSet);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (bowlingSet.initializing) {
      setLane('');
      setLane('');
      setTarget('');
      setEvent('');
      setLaydown('');
      setEventType('appTesting');
      setAppActions(<></>);
    }
  }, [bowlingSet.initializing, setAppActions]);

  if (bowlingSet.inProgress && !bowlingSet.initializing) return <>{children}</>;

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="Where">
          <StepLabel>What are you bowling</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Event"
              value={event}
              onChange={e => setEvent(e.target.value)}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!event}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button
                  disabled={true}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="type">
          <StepLabel>Event type</StepLabel>
          <StepContent>
            <Select
              labelId="event-type-select-label"
              id="event-type"
              value={eventType}
              label="Event Type"
              onChange={handleEventTypeChange}
            >
              <MenuItem value="appTesting">App Testing</MenuItem>
              <MenuItem value="practice">Practice</MenuItem>
              <MenuItem value="league">League</MenuItem>
              <MenuItem value="tournament">Tournament</MenuItem>
            </Select>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!event}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="Lane">
          <StepLabel>What lane are you starting on</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Lane number"
              value={lane}
              onChange={e => setLane(parseInt(e.target.value))}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!lane}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="laydown">
          <StepLabel>Starting laydown board</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Laydown"
              value={laydown}
              onChange={e => setLaydown(e.target.value)}
              inputProps={{
                inputMode: 'decimal',
              }}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!parseFloat(laydown)}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="target">
          <StepLabel>Initial Target Board</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Target #"
              value={target}
              onChange={e => setTarget(e.target.value)}
              inputProps={{
                inputMode: 'decimal',
              }}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!parseFloat(target)}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === 5 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(
                actions.start({
                  event: event,
                  inProgress: true,
                  startingLane: lane as number,
                  type: eventType,
                  target: parseFloat(target),
                  laydown: parseFloat(laydown),
                }),
              );
              handleReset();
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            Lets Go!
          </Button>
        </Paper>
      )}
    </Box>
  );
}
