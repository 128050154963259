import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { TargetListState } from './types';

const savedItems = localStorage.getItem('saved_targets');
export const initialState: TargetListState = {
  items: savedItems ? JSON.parse(savedItems) : [],
  //   {
  //     id: 'fda',
  //     label: 'Spares - 10',
  //     link: 'http://localhost:3000/?l=26.0&tb=21.0&td=15.7&eb=12.4&ed=42.9&fp=6.9',
  //   },
  //   {
  //     id: 'fdb',
  //     label: 'aSpares - 10',
  //     link: 'http://localhost:3000/?l=26.0&tb=21.0&td=15.7&eb=12.4&ed=42.9&fp=6.9',
  //   },
  //   {
  //     id: 'fd3',
  //     label: 'zSpares - 10',
  //     link: 'http://localhost:3000/?l=26.0&tb=21.0&td=15.7&eb=12.4&ed=42.9&fp=6.9',
  //   },
  // ],
};

const slice = createSlice({
  name: 'targetList',
  initialState,
  reducers: {
    saveTargetLink(
      state,
      action: PayloadAction<{ label: string; link: string }>,
    ) {
      state.items.push({
        id: new Date().valueOf().toString(),
        ...action.payload,
      });
      localStorage.setItem('saved_targets', JSON.stringify(state.items));
    },
    deleteTargetLink(state, action: PayloadAction<{ id: string }>) {
      const items = state.items.filter(item => item.id !== action.payload.id);
      localStorage.setItem('saved_targets', JSON.stringify(items));
      return {
        ...state,
        items,
      };
    },
  },
});

export const { actions: targetListActions } = slice;

export const useTargetListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useTargetListSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
