import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoftControlSlice } from './slice';
import { selectLoftControl } from './slice/selectors';
import { Loft } from './slice/types';

interface Props {
  open: boolean;
  setOpen: Function;
}
export function LoftDrawer(props: Props) {
  const { open, setOpen } = props;
  const actions = useLoftControlSlice().actions;
  const dispatch = useDispatch();
  const loft = useSelector(selectLoftControl);
  const handleChange = (event: SelectChangeEvent) => {
    dispatch(actions.setTarget(parseInt(event.target.value)));
  };
  const handleExecution = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    dispatch(actions.setExecution(parseInt(value || '0')));
  };

  const handleTargetSliderChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    dispatch(actions.setTarget(newValue as number));
  };

  const handleExecutionSliderChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    dispatch(actions.setExecution(newValue as number));
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Box paddingTop={6} padding={2} margin={2} width="75vw">
        <Typography>Target Loft (ft)</Typography>
        <Slider
          value={loft.target}
          step={1}
          marks
          min={0}
          max={15}
          onChange={handleTargetSliderChange}
          valueLabelDisplay="auto"
        />

        <Typography>Execution</Typography>
        <Slider
          value={loft.execution}
          step={1}
          marks
          min={0}
          max={15}
          onChange={handleExecutionSliderChange}
          valueLabelDisplay="auto"
        />
      </Box>
    </Drawer>
  );
}
