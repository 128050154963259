/**
 *
 * RecapsPage
 *
 */
import { selectBowlingSet } from 'app/components/BowlingSet/slice/selectors';
import { SetList } from 'app/components/SetList';
import { useSetSearchSlice } from 'app/components/SetSearch/slice';
import { selectSetSearch } from 'app/components/SetSearch/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {}

export function RecapsPage(props: Props) {
  const { actions } = useSetSearchSlice();
  const dispatch = useDispatch();
  const sets = useSelector(selectSetSearch);

  React.useEffect(() => {
    dispatch(actions.listSets());
  }, [actions, dispatch]);

  if (sets.rows) {
    return <SetList rows={sets.rows} />;
  }
  return <div>Hello</div>;
}
