/**
 *
 * PinFallController
 *
 */
import * as React from 'react';
import { LanePinDeck } from '../lane/LanePinDeck';
import { pinFallControllerActions, usePinFallControllerSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPinFallController } from './slice/selectors';

interface Props {}

export function PinFallController(props: Props) {
  usePinFallControllerSlice();
  const dispatch = useDispatch();
  const pinFallController = useSelector(selectPinFallController);

  return (
    <LanePinDeck
      pinState={pinFallController.status.map(pin => {
        return {
          ...pin,
          color: 'red',
          onClick: () => dispatch(pinFallControllerActions.togglePin(pin)),
        };
      })}
    />
  );
}
