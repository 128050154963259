import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { getBoard } from 'utils/pattern';

function boardValueParser(value) {
  return getBoard(value) || value;
}

const options: Partial<GridColDef> = {
  headerAlign: 'left',
  align: 'left',
  width: 90,
  editable: true,
  type: 'number',
};

export const columns: GridColDef[] = [
  {
    ...options,
    field: 'num',
    headerName: 'Num',
    editable: false,
  },
  {
    ...options,
    field: 'start',
    headerName: 'start',
    type: 'string',
    valueParser: boardValueParser,
  },
  {
    ...options,
    field: 'stop',
    headerName: 'stop',
    type: 'string',
    valueParser: boardValueParser,
  },
  {
    ...options,
    field: 'load',
    headerName: 'loads',
  },
  {
    ...options,
    field: 'mics',
    headerName: 'mics',
  },
  {
    ...options,
    field: 'speed',
    headerName: 'speed',
  },
  {
    ...options,
    field: 'buf',
    headerName: 'buf',
  },
  {
    ...options,
    field: 'tank',
    headerName: 'tank',
    type: 'string',
  },
  {
    ...options,
    field: 'startDistance',
    headerName: 'Start Distance',
  },
  {
    ...options,
    field: 'stopDistance',
    headerName: 'Stop Distance',
  },
  {
    ...options,
    field: 'toil',
    headerName: 'Total Oil',
  },
];
