import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { networkManagerSaga } from './saga';
import { NetworkManagerState } from './types';

export const initialState: NetworkManagerState = {
  isFaulting: false,
  faultsSinceLastSuccess: 0,
};

const slice = createSlice({
  name: 'networkManager',
  initialState,
  reducers: {
    fault(state, action: PayloadAction<any>) {
      if (!state.isFaulting) {
        state.lastFaultBeginTime = new Date();
      }
      state.isFaulting = true;
      state.faultsSinceLastSuccess++;
    },
    recovered(state, action: PayloadAction<any>) {
      state.isFaulting = false;
      state.faultsSinceLastSuccess = 0;
      state.lastSuccessTime = new Date();
    },
  },
});

export const { actions: networkManagerActions } = slice;

export const useNetworkManagerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: networkManagerSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useNetworkManagerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
