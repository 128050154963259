import { Card, CardContent, Stack, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  lane: string;
  laydownBoard: number;
  targetBoard: number;
  targetDistance: number;
}
export function TargetCard(props: Props) {
  const { lane, laydownBoard, targetBoard, targetDistance } = props;

  return (
    <Card>
      <CardContent>
        <Typography color="primary" gutterBottom>
          {lane}
        </Typography>
        <Stack padding={0} spacing={0}>
          <Typography fontSize={'.5rem'} variant="body2">
            Laydown:
          </Typography>
          <Typography fontSize={'.9rem'}>
            {laydownBoard.toFixed(1).toString()}
          </Typography>
          <Typography fontSize={'.5em'}>Target: </Typography>
          <Typography fontSize={'.9em'} noWrap overflow={'visible'}>
            {targetBoard.toFixed(1).toString()} @{' '}
            {targetDistance.toFixed(1).toString()}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
