import { Box, Drawer, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { BallDeckExitPoint } from '../BallDeckExitPoint';
import { PinFallController } from '../PinFallController';
import { LANE_WIDTH, PIN_DECK_LENGTH } from '../lane/Lane/constants';
import Lane from '../lane/Lane';
import { TargetDialogs } from './Dialogs';

interface Props {
  open: 0 | 1 | 2;
  setOpen: Function;
  selector: any;
  actions: any;
}

export function Target(props: Props) {
  const [openSetLaydown, setOpenSetLaydown] = React.useState(false);
  const [openSetTarget, setOpenSetTarget] = React.useState(false);
  const [openSetExitpoint, setOpenSetExitpoint] = React.useState(false);
  const [proposedNewTarget, setProposedNewMarker] = React.useState({
    board: -1,
    distance: -1,
  });

  const { open, setOpen, selector: lane, actions } = props;
  return (
    <>
      <Drawer open={open === 2 ? true : false} onClose={() => setOpen(0)}>
        <Box
          width="100%"
          height="100%"
          component={Grid}
          overflow={'hidden'}
          item
          xs={12}
          padding={0}
          margin={0}
        >
          <Stack>
            <div
            // style={{ position: 'fixed', height: '100%', overflow: 'scroll' }}
            >
              <svg
                height="90vh"
                width="90vw"
                viewBox={`0 0 ${LANE_WIDTH * 2} ${
                  PIN_DECK_LENGTH + 60 * 12 + 14
                }`}
                preserveAspectRatio="xMidYMid meet"
                transform="scale(4,1)"
                pointerEvents="none"
              >
                <Lane
                  selector={lane}
                  setOpenSetLaydown={() => setOpenSetLaydown(true)}
                  setOpenSetTarget={() => setOpenSetTarget(true)}
                  setOpenSetExitpoint={() => setOpenSetExitpoint(true)}
                  showMarkerLabels
                />
              </svg>
            </div>
            <TargetDialogs
              actions={actions}
              selector={lane}
              openSetLaydown={openSetLaydown}
              setOpenSetLaydown={setOpenSetLaydown}
              openSetTarget={openSetTarget}
              setOpenSetTarget={setOpenSetTarget}
              setOpenSetExitpoint={setOpenSetExitpoint}
              openSetExitpoint={openSetExitpoint}
              proposedNewTarget={proposedNewTarget}
              setProposedNewMarker={setProposedNewMarker}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
