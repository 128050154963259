import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.lane || initialState;

export const selectLane = createSelector([selectSlice], state => {
  return {
    ...state,
    pixelsPerInch: (state.laneHeight / (12 * 63)) * state.yScaleFactor,
  };
});

const selectTargetLeftLaneSlice = (state: RootState) =>
  state.targetLeftLane || initialState;
export const selectTargetLeftLane = createSelector(
  [selectTargetLeftLaneSlice],
  state => {
    return {
      ...state,
      pixelsPerInch: (state.laneHeight / (12 * 63)) * state.yScaleFactor,
    };
  },
);

export const selectTargetLeftLaneReport = createSelector(
  [selectTargetLeftLaneSlice],
  state => state.encodedLine,
);

const selectTargetRightLaneSlice = (state: RootState) =>
  state.targetRightLane || initialState;
export const selectTargetRightLane = createSelector(
  [selectTargetRightLaneSlice],
  state => {
    return {
      ...state,
      pixelsPerInch: (state.laneHeight / (12 * 63)) * state.yScaleFactor,
    };
  },
);

export const selectTargetRightLaneReport = createSelector(
  [selectTargetRightLaneSlice],
  state => state.encodedLine,
);

// export const selectTargetBoard = createSelector(
//   [selectSlice],
//   state => state.targetBoard,
// );
