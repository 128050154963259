/**
 *
 * Sso
 *
 */
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSsoSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSso } from './slice/selectors';

interface Props {}

const IMAGE_ID = '69cdcc2e-6488-414d-ab81-e39216579863';
export const Sso = React.memo((props: Props) => {
  const actions = useSsoSlice().actions;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const ssoState = useSelector(selectSso);
  React.useEffect(() => {
    document
      .getElementById('loading')
      ?.animate(
        [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
        { duration: 600, iterations: 100 },
      );

    // if (ssoState.isAuthed) {
    //   window.location.replace(process.env.REACT_APP_URI as string);
    //   return;
    // }
    const code = searchParams.get('code');
    if (searchParams.size > 0 && searchParams.get('code')) {
      dispatch(actions.auth({ codeGrant: code as string }));
    }
  });

  return (
    <>
      {ssoState.isAuthed === false ? (
        <Box
          margin={10}
          alignContent={'center'}
          justifyContent={'center'}
          display="flex"
          sx={{
            animation: 'spin 2s linear infinite',
          }}
        >
          <img
            id="loading"
            width={200}
            src={`/images/${IMAGE_ID}.png`}
            alt={'loading'}
          />
        </Box>
      ) : (
        <Typography>Signed in success</Typography>
      )}
    </>
  );
});
