/**
 *
 * PatternCompare
 *
 */
import { laneActions, useLaneSlice } from 'app/components/lane/Lane/slice';
import * as React from 'react';
import { usePaternCompareSlice, paternCompareActions } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLane } from 'app/components/lane/Lane/slice/selectors';
import { selectPaternCompare } from './slice/selectors';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { PatternEditor } from '../PatternEditor';
import { Link as MuiLink } from '@mui/material';
import { BrowserExperienceNotice } from 'app/components/BrowserExperienceNotice';

interface Props {}

export function PatternCompare(props: Props) {
  useLaneSlice();
  usePaternCompareSlice();
  const lane = useSelector(selectLane);
  const patternCompare = useSelector(selectPaternCompare);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(paternCompareActions.addPatterns(lane.patterns));
  }, [dispatch, lane.patterns]);

  const options = lane.patterns.map(option => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const onPatternChange = (event, newValue) => {
    console.log(newValue);
    dispatch(paternCompareActions.setSelectedPatterns(newValue));
    // if (newValue?.name) {
    //   dispatch(laneActions.setPattern(newValue.name));
    // } else {
    //   dispatch(laneActions.setPattern(''));
    // }
  };

  return (
    <>
      <BrowserExperienceNotice pageId="patterncompare" />
      <Box padding={2}>
        <Container maxWidth="md">
          <Autocomplete
            fullWidth={false}
            multiple
            id="combo-box-patterns"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
            )}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <div ref={params.InputProps.ref}>
                <TextField {...params} label="Pattern" />
              </div>
            )}
            onChange={onPatternChange}
          />
        </Container>
        <Stack direction={'row'}>
          {patternCompare.patternsToCompare.map(pattern => (
            <Container maxWidth="xs">
              <PatternEditor pattern={pattern} />
            </Container>
          ))}
        </Stack>
      </Box>
    </>
  );
}
