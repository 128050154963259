import styled from '@emotion/styled';

const Wrapper = styled.rect<{
  $boardSelected?: boolean;
  $board: number;
  $opacity: number;
}>`
  fill: ${props => (props.$boardSelected === true ? 'blue' : 'BlanchedAlmond')};
  opacity: ${props => props.$opacity};
  stroke: black;
  stroke-width: 0.18;
  stroke-opacity: ${props => (props.$board % 5 ? 0.15 : 1)};
`;

export default Wrapper;
