/**
 *
 * LaneArrows
 *
 */

import React from 'react';
import LaneArrow from './LaneArrow';
import { BOARD_WIDTH } from '../Lane/constants';

interface Props {
  height: number;
  boardWidth: number;
}

export const LaneArrows = (props: Props) => {
  const generateArrows = props => {
    const arrows = [];
    const arrowHeight = 6;
    const rankSeparation = 24 / 3;

    return (
      <svg>
        <LaneArrow
          key="arrow-35"
          x={props.boardWidth * 5 - props.boardWidth / 2}
          y={3 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />
        <LaneArrow
          key="arrow-05"
          x={props.boardWidth * 35 - props.boardWidth / 2}
          y={3 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />

        <LaneArrow
          key="arrow-30"
          x={props.boardWidth * 10 - props.boardWidth / 2}
          y={2 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />
        <LaneArrow
          key="arrow-10"
          x={props.boardWidth * 30 - props.boardWidth / 2}
          y={2 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />

        <LaneArrow
          key="arrow-25"
          x={props.boardWidth * 15 - props.boardWidth / 2}
          y={1 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />
        <LaneArrow
          key="arrow-15"
          x={props.boardWidth * 25 - props.boardWidth / 2}
          y={1 * rankSeparation}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />

        <LaneArrow
          key="arrow-20"
          x={props.boardWidth * 20 - props.boardWidth / 2}
          y={0}
          height={arrowHeight}
          width={BOARD_WIDTH}
        />
      </svg>
    );
  };
  return generateArrows(props);
};

export default LaneArrows;
